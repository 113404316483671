import React, { useState, useEffect, useContext } from "react";
import { ChromePicker } from "react-color";
import { AppContext } from "../AppContext";

const Text = () => {
  const {
    canvasStates,
    setCanvasStates,
    activePage,
    setSelectedShape,
    setIsTransforming,
  } = useContext(AppContext);
  const canvasState = canvasStates[activePage];

  const [rangeValue, setRangeValue] = useState(40);
  const [availableFonts, setAvailableFonts] = useState([]);

  const [text, setText] = useState("");
  const [color, setColor] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });

  const [font, setFont] = useState("Arial");
  const [fontSize, setFontSize] = useState(40);

  useEffect(() => {
    const commonFonts = [
      "Arial",
      "Verdana",
      "Helvetica",
      "Times New Roman",
      "Courier New",
      "Georgia",
      "Palatino",
      "Garamond",
      "Bookman",
      "Comic Sans MS",
      "Trebuchet MS",
      "Arial Black",
      "Impact",
    ];

    const fonts = commonFonts
      .sort()
      .filter((font) => document.fonts.check(`12px "${font}"`));

    setAvailableFonts(fonts);
  }, []);

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setColor(color.rgb);
  };

  const handleDeleteText = (index) => {
    setCanvasStates((prevState) => {
      const updatedTexts = prevState[activePage].elements.filter(
        (_, i) => i !== index
      );
      setSelectedShape(null);
      setIsTransforming(false);
      return {
        ...prevState,
        [activePage]: {
          ...prevState[activePage],
          elements: updatedTexts,
        },
      };
    });
  };

  const styles = {
    color: {
      width: "34px",
      height: "14px",
      borderRadius: "2px",
      background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
    },
    swatch: {
      padding: "5px",
      background: "#fff",
      borderRadius: "1px",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      display: "inline-block",
      cursor: "pointer",
    },
    popover: {
      position: "absolute",
      zIndex: "2",
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  };

  const handleAddText = () => {
    const textObj = {
      type: "text",
      text: text,
      size: fontSize,
      font: font,
      color: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      x: 0,
      y: 0,
    };
    setCanvasStates((prevState) => ({
      ...prevState,
      [activePage]: {
        ...prevState[activePage],
        elements: [...prevState[activePage].elements, textObj],
      },
    }));
  };

  return (
    <div className="flex flex-col gap-2 text-center">
      <span className="text-lg mb-2 font-medium">Dodaj tekst:</span>
      {/* Text */}
      <span className="mr-auto font-medium">Tekst: </span>
      <input
        type="text"
        placeholder="Twój tekst"
        className="input input-bordered w-full max-w-xs"
        maxLength={100}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <span className="mr-auto font-medium">Czcionka: </span>
      <div className="flex items-center gap-2">
        {/* Font name */}
        <select
          className="select select-bordered w-full"
          onChange={(e) => setFont(e.target.value)}
        >
          {availableFonts.map((font) => (
            <option key={font} style={{ fontFamily: font }}>
              {font}
            </option>
          ))}
        </select>
        {/* Font size */}
        <select
          value={fontSize}
          onChange={(e) => setFontSize(e.target.value)}
          className="select select-bordered max-w-20"
        >
          <option value="8">8</option>
          <option value="10">10</option>
          <option value="12">12</option>
          <option value="14">14</option>
          <option value="16">16</option>
          <option value="18">18</option>
          <option value="20">20</option>
          <option value="24">24</option>
          <option value="28">28</option>
          <option value="32">32</option>
          <option value="36">36</option>
          <option value="48">48</option>
          <option value="72">72</option>
        </select>
      </div>
      <div className="flex items-center gap-2">
        <span className="mr-auto font-medium">Kolor: </span>
        <div className="flex">
          <div style={styles.swatch} onClick={handleClick}>
            <div style={styles.color} />
          </div>
        </div>
      </div>
      <div className="relative">
        {displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={handleClose} />
            {/* Font color */}
            <ChromePicker color={color} onChange={handleChange} disableAlpha />
          </div>
        ) : null}
      </div>
      <button className="btn btn-primary text-white" onClick={handleAddText}>
        Dodaj
      </button>
      {/* <div className="mt-4">
        {canvasState.elements.map((textObj, index) => (
          <div key={index} className="flex items-center justify-between">
            <span style={{ fontFamily: textObj.font }}>{textObj.text}</span>
            <button
              className="btn btn-danger"
              onClick={() => handleDeleteText(index)}
            >
              Usuń
            </button>
          </div>
        ))}
      </div> */}
      {/* List elements that type=text and place delete button next to it */}
      <div className="mt-4 flex flex-col gap-2">
        <h2 className="text-xl pb-2 font-medium">Teksty:</h2>
        {canvasState.elements
          .filter((textObj) => textObj.type === "text") // Filter only text elements
          .map((textObj, index) => (
            <div
              key={index}
              className="flex items-center justify-between border border-1 p-2 border-gray-300 rounded-md pl-2"
            >
              <span
                className="w-full overflow-hidden text-left mr-2 italic"
                style={{ fontFamily: textObj.font }}
              >
                {textObj.text}
              </span>
              <button
                className="text-red-700 font-medium px-2 py-1 rounded-md border border-red-700"
                onClick={() => handleDeleteText(index)}
              >
                Usuń
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Text;
