import React, { useState, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import facebook from "../assets/facebook.svg";
import google from "../assets/google.svg";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { AppContext } from "../AppContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  // const { userdata, setUserdata } = useContext(AppContext);

  const handleLogin = async () => {
    if (!email || !password) {
      setError("Email and password are required.");
      return;
    }

    try {
      const response = await axios.post(
        "https://spokodruczki.pl:5000/api/login",
        { email, password }
      );
      console.log("Response data:", response.data); // Log the entire response data for debugging

      const token = response.data.token;
      // setUserdata(jwtDecode(token));
      console.log(jwtDecode(token));

      localStorage.setItem("token", token);
      // localStorage.setItem("accountType", decoded.accountType);
      // localStorage.setItem("email", decoded.email);

      setSuccess("Login successful.");
      setError("");

      navigate("/orders"); // Navigate to the orders page
    } catch (error) {
      setError(error.response ? error.response.data : "Login failed.");
      setSuccess("");
    }
  };

  return (
    <div className="h-svh w-svw flex flex-col items-center justify-center bg-blue-50">
      <div className="card bg-white shadow-2xl p-4 gap-2">
        <h2 className="font-bold text-2xl mb-2">Zaloguj się</h2>
        {error && <div className="text-red-500">{error}</div>}
        {success && <div className="text-green-500">{success}</div>}
        <input
          type="text"
          placeholder="Email"
          className="input input-bordered"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          className="input input-bordered"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleLogin} className="btn btn-primary mt-4">
          Login
        </button>
        {/* <div className="flex gap-2">
          <button className="btn flex flex-1 bg-gray-100 items-center justify-center">
            <img
              src={google}
              alt="Zaloguj przez konto Google"
              className="w-6 h-6"
            />
          </button>
          <button className="btn flex flex-1 bg-gray-100 items-center justify-center">
            <img
              src={facebook}
              alt="Zaloguj przez konto Facebook"
              className="w-10 h-10"
            />
          </button>
        </div> */}
        <div className="text-center mt-2">lub</div>
        <div className="text-center">
          <Link
            to="/register"
            className="btn btn-link"
            style={{ fontSize: "1em" }}
          >
            Zarejestruj się
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
