import React, { useEffect, useState, useRef, useContext } from "react";
import Konva from "konva";
import {
  Stage,
  Layer,
  Image as KonvaImage,
  Transformer,
  Group,
  Text,
  Rect,
  Line,
} from "react-konva";
import { AppContext } from "../AppContext";
import Zoom from "./Zoom";
import Calendar from "./Calendar";
import { HiOutlineArrowSmUp } from "react-icons/hi";
import { HiOutlineArrowSmDown } from "react-icons/hi";
import { FaRegTrashAlt } from "react-icons/fa";
import { BsLayerForward } from "react-icons/bs";
import { BsLayerBackward } from "react-icons/bs";

const Calendarium = ({ activePage }) => {
  const { canvasWidth, canvasHeight } = useContext(AppContext);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const img = new window.Image();
    img.src = require(`../assets/calendariums/${activePage}.png`); // Replace with your image URL
    img.onload = () => {
      setImage(img); // Set the image once it's loaded
    };
    img.onerror = () => {
      console.error("Failed to load image. Check the URL.");
    };
  }, [activePage]);

  return (
    <KonvaImage
      x={0}
      y={0}
      width={canvasWidth}
      height={canvasHeight}
      image={image}
      listening={false}
    />
  );
};

const Canvas = ({ stageRef, artboardRef, dialogRef }) => {
  const {
    canvasStates,
    setCanvasStates,
    setCanvasThumbnails,
    activePage,
    setActivePage,
    activeTool,
    setActiveTool,
    holidays,
    scale,
    setScale,
    isTransforming,
    setIsTransforming,
    selectedDay,
    setSelectedDay,
    selectedShape,
    setSelectedShape,
    setHolidayName,
    setColor,
    saveHistory,
    handleCanvasStateChange,
    pages,
    months,
    fullSizeRefs,
    calendarDays,
    setCalendarDays,
    isHoliday,
    currentYear,
    yearMonths,
    monthIndex,
    stagePosition,
    setStagePosition,
    productType,
    productConfig,
  } = useContext(AppContext);
  const canvasState = canvasStates[activePage];
  const setCanvasState = handleCanvasStateChange;

  const canvasWidth = productConfig.width;
  const canvasHeight = productConfig.height;
  // const calendariumPath = productConfig.calendariumPath(activePage);

  const [text, setText] = useState("Click to Edit");
  const [textScale, setTextScale] = useState({ x: 1, y: 1 });
  const [isEditing, setIsEditing] = useState(false);
  const textRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.style.width = textRef.current?.width() * scale + "px";
  }, [text]);

  const handleTextClick = () => {
    setIsEditing(true);
    const textNode = textRef.current;
    const textPosition = textNode.getAbsolutePosition();
    // console.log("Text position: " + textPosition.x + ", " + textPosition.y);
    // const stageBox = textNode.getStage().container().getBoundingClientRect();
    // console.log("Stage box: " + stageBox.left + ", " + stageBox.top);
    const { y, x } = stagePosition;
    // console.log("x: " + x);
    // console.log("y: " + y);

    inputRef.current.style.width = textRef.current.width() * scale + "px";
    inputRef.current.style.position = "absolute";
    inputRef.current.style.top = textPosition.y - 50 * scale + "px";
    inputRef.current.style.left = textPosition.x + "px";
    inputRef.current.style.display = "block";
    inputRef.current.focus();
  };

  const handleInputBlur = () => {
    inputRef.current.style.display = "none";
    setText(inputRef.current.value);
    setIsEditing(false);
  };

  const [textPosition, setTextPosition] = useState({ x: 0, y: 0 });

  const handleTextDragEnd2 = (e) => {
    const node = e.target;
    const newX = node.x();
    const newY = node.y();

    setTextPosition({ x: newX, y: newY });

    // setCanvasStates((prevState) => {
    //   const updatedTexts = prevState[activePage].texts.map((textObj, i) =>
    //     i === index ? { ...textObj, x: newX, y: newY } : textObj
    //   );

    //   return {
    //     ...prevState,
    //     [activePage]: {
    //       ...prevState[activePage],
    //       texts: updatedTexts,
    //     },
    //   };
    // });
  };

  /////////////////////

  const handleElementResize = (e, index) => {
    const node = e.target;
    const x = node.x();
    const y = node.y();
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    const rotation = node.rotation();

    setCanvasStates((prevState) => {
      const newElements = prevState[activePage].elements.map((element, i) =>
        i === index
          ? {
              ...element,
              scaleX: scaleX,
              scaleY: scaleY,
              rotation: rotation,
              x: x,
              y: y,
            }
          : element
      );

      return {
        ...prevState,
        [activePage]: {
          ...prevState[activePage],
          elements: newElements,
        },
      };
    });
  };

  const handleTextResize2 = (e) => {
    const node = e.target;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    setTextScale({ x: scaleX, y: scaleY });
  };

  const dayNames = ["Pn", "Wt", "Śr", "Czw", "Pt", "Sb", "Nd"];
  // const monthIndex = 1;

  const [isZooming, setIsZooming] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 });

  const SNAP_THRESHOLD = 60; // Adjust this value as needed

  const snapToPosition = (position, target) => {
    return Math.abs(position - target) < SNAP_THRESHOLD ? target : position;
  };

  const handleMouseDown = (e) => {
    const clientX = e.evt.clientX || e.evt.touches[0].clientX;
    const clientY = e.evt.clientY || e.evt.touches[0].clientY;

    if (activeTool === "Przesuń") {
      setIsDragging(true);
      setLastMousePosition({ x: clientX, y: clientY });
    } else if (activeTool === "Wybierz" && e.target === e.target.getStage()) {
      setIsTransforming(false);
      setSelectedShape(null);
    }
  };

  const handleMouseMove = (e) => {
    const clientX = e.evt.clientX || e.evt.touches[0].clientX;
    const clientY = e.evt.clientY || e.evt.touches[0].clientY;
    // setLastMousePosition({ x: e.evt.clientX, y: e.evt.clientY });
    if (isDragging && activeTool === "Przesuń") {
      const dx = clientX - lastMousePosition.x;
      const dy = clientY - lastMousePosition.y;
      setStagePosition((prevPosition) => ({
        x: prevPosition.x + dx,
        y: prevPosition.y + dy,
      }));
      setLastMousePosition({ x: clientX, y: clientY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const [artboardSize, setArtboardSize] = useState({
    width: canvasWidth,
    height: canvasHeight,
  });
  const calendarWidth = 0.9 * artboardSize.width;
  const calendarDayWidth = calendarWidth / 7;
  const calendarDayHeight = calendarDayWidth * 0.7;
  const calendarY = artboardSize.height / 2;
  const [backgroundColor, setBackgroundColor] = useState("white");

  const [calendarium, setCalendarium] = useState(null);

  const containerRef = useRef(null); // Reference for the parent div
  const imageRef = useRef(null);
  const transformerRef = useRef(null);

  useEffect(() => {
    const handleWheel = (e) => {
      if (e.ctrlKey || e.altKey) {
        setIsZooming(true);
        e.preventDefault();
        const scaleBy = 1.1;
        const oldScale = scale;
        const newScale = e.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;

        const stage = stageRef.current;
        const pointer = stage.getPointerPosition();

        const mousePointTo = {
          x: (pointer.x - stage.x()) / oldScale,
          y: (pointer.y - stage.y()) / oldScale,
        };

        setScale(newScale);

        const newPos = {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        };

        setStagePosition(newPos);
      }
    };

    const stage = stageRef.current;
    stage.content.addEventListener("wheel", handleWheel);

    return () => {
      stage.content.removeEventListener("wheel", handleWheel);
    };
  }, [scale, stageRef]);

  useEffect(() => {
    // Fit the artboard to the stage height
    const stageWidth = stageRef.current.width();
    const stageHeight = stageRef.current.height();
    const artboardWidth = artboardSize.width;
    const artboardHeight = artboardSize.height;

    if (!isZooming) {
      // Calculate the scale to fit the artboard within the stage
      const scaleX = stageWidth / artboardWidth;
      const scaleY = stageHeight / artboardHeight;
      const newScale = Math.min(scaleX, scaleY) * 0.95; // Apply a margin
      if (scale !== newScale) {
        setScale(newScale);

        // Center the artboard
        setStagePosition({
          x: (stageWidth - artboardWidth * newScale) / 2,
          y: (stageHeight - artboardHeight * newScale) / 2,
        });
      }
    }
  }, [stageRef, scale, artboardSize]);

  // Set appropriate artboard size based on the product type
  useEffect(() => {
    setArtboardSize({
      width: canvasWidth,
      height: canvasHeight,
    });
  }, [productType]);

  const handleImageDragEnd = (e) => {
    const node = e.target;
    const stage = node.getStage();
    const artboardWidth = artboardRef.current.width();
    const artboardHeight = artboardRef.current.height();
    const imageWidth = node.width() * node.scaleX(); // Image width after scaling
    const imageHeight = node.height() * node.scaleY(); // Image height after scaling

    const newX = snapToPosition(node.x(), 0); // Left
    const newY = snapToPosition(node.y(), 0); // Top
    const newCenterX = snapToPosition(
      node.x(),
      (artboardWidth - imageWidth) / 2
    ); // Center X
    const newCenterY = snapToPosition(
      node.y(),
      (artboardHeight - imageHeight) / 2
    ); // Center Y
    const newRight = snapToPosition(node.x(), artboardWidth - imageWidth); // Right
    const newBottom = snapToPosition(node.y(), artboardHeight - imageHeight); // Bottom

    const snappedX =
      newX === node.x()
        ? newCenterX === node.x()
          ? newRight
          : newCenterX
        : newX;
    const snappedY =
      newY === node.y()
        ? newCenterY === node.y()
          ? newBottom
          : newCenterY
        : newY;

    node.position({
      x: snappedX,
      y: snappedY,
    });

    const newState = {
      ...canvasState,
      x: node.x(),
      y: node.y(),
    };
    setCanvasState(newState);
    saveHistory();
  };
  const handleImageResize = (e) => {
    const node = e.target;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    const width = node.width();
    const height = node.height();
    const rotation = node.rotation();

    const newState = {
      ...canvasState,
      x: node.x(),
      y: node.y(),
      width: width,
      height: height,
      scaleX: scaleX,
      scaleY: scaleY,
      rotation: rotation,
    };
    setTimeout(() => {
      setCanvasState(newState);
    }, 0);
    saveHistory();
  };

  const handleDayClick = (day) => {
    setSelectedDay(day.toString());
    if (selectedDay === day.toString()) {
      dialogRef.current.showModal();
    } else {
      setSelectedDay(day.toString());
    }
  };

  useEffect(() => {
    if (selectedDay !== null) {
      dialogRef.current.showModal();
    }
  }, [selectedDay]);

  const handleSelect = (shapeRef) => {
    setSelectedShape(shapeRef);
    setIsTransforming(true);
  };

  useEffect(() => {
    if (selectedShape && transformerRef.current) {
      transformerRef.current.nodes([selectedShape]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [selectedShape]);

  const handleElementDragEnd = (e, index) => {
    const node = e.target;
    const newX = node.x();
    const newY = node.y();

    setCanvasStates((prevState) => {
      const updatedTexts = prevState[activePage].elements.map((textObj, i) =>
        i === index ? { ...textObj, x: newX, y: newY } : textObj
      );

      return {
        ...prevState,
        [activePage]: {
          ...prevState[activePage],
          elements: updatedTexts,
        },
      };
    });
  };

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    elementIndex: null,
  });
  const handleContextMenu = (e, index) => {
    e.evt.preventDefault();
    const stage = stageRef.current;
    const containerRect = stage.container().getBoundingClientRect();
    setContextMenu({
      visible: true,
      x: e.evt.clientX - containerRect.left,
      y: e.evt.clientY - containerRect.top,
      elementIndex: index,
    });
  };

  const handleMoveUp = () => {
    const index = contextMenu.elementIndex;
    if (index > 0) {
      setCanvasStates((prevState) => {
        const newElements = [...prevState[activePage].elements];
        [newElements[index - 1], newElements[index]] = [
          newElements[index],
          newElements[index - 1],
        ];
        return {
          ...prevState,
          [activePage]: {
            ...prevState[activePage],
            elements: newElements,
          },
        };
      });
    }
    setContextMenu({ visible: false, x: 0, y: 0, elementIndex: null });
  };

  const handleMoveDown = () => {
    const index = contextMenu.elementIndex;
    if (index < canvasStates[activePage].elements.length - 1) {
      setCanvasStates((prevState) => {
        const newElements = [...prevState[activePage].elements];
        [newElements[index + 1], newElements[index]] = [
          newElements[index],
          newElements[index + 1],
        ];
        return {
          ...prevState,
          [activePage]: {
            ...prevState[activePage],
            elements: newElements,
          },
        };
      });
    }
    setContextMenu({ visible: false, x: 0, y: 0, elementIndex: null });
  };

  const handleDelete = () => {
    const index = contextMenu.elementIndex;
    setCanvasStates((prevState) => {
      const newElements = prevState[activePage].elements.filter(
        (_, i) => i !== index
      );
      return {
        ...prevState,
        [activePage]: {
          ...prevState[activePage],
          elements: newElements,
        },
      };
    });
    setContextMenu({ visible: false, x: 0, y: 0, elementIndex: null });
  };

  const handleCloseContextMenu = () => {
    setContextMenu({ visible: false, x: 0, y: 0, elementIndex: null });
  };

  const handleTransform = (newBox, oldBox) => {
    // Calculate the aspect ratio
    const aspectRatio = oldBox.width / oldBox.height;

    // Keep the new width and height proportional
    return {
      ...newBox,
      height: newBox.width / aspectRatio,
    };
  };

  return (
    <div
      className="w-full h-full flex flex-col items-center justify-center p-2 relative overflow-hidden bg-neutral-500"
      ref={containerRef}
      onClick={handleCloseContextMenu}
    >
      {/* Konva Stage for manipulating page, shrunk to fit in the parent container */}
      {productType === "Kalendarz" ? (
        <Stage
          width={containerRef.current ? containerRef.current.offsetWidth : 0}
          height={containerRef.current ? containerRef.current.offsetHeight : 0}
          style={{
            cursor:
              activeTool === "Pisak"
                ? "crosshair"
                : activeTool === "Tekst"
                ? "text"
                : activeTool === "Przesuń"
                ? "grab"
                : "default",
          }}
          ref={stageRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onTouchStart={handleMouseDown}
          onTouchMove={handleMouseMove}
          onTouchEnd={handleMouseUp}
          scaleX={scale}
          scaleY={scale}
          x={stagePosition.x}
          y={stagePosition.y}
          // listening={activeTool === "Przesuń"}
        >
          <Layer>
            {/* Artboard bg */}
            <Group>
              <Rect
                width={canvasWidth + 100}
                height={canvasHeight + 100}
                x={-50}
                y={-50}
                fill="white"
              />
              <Rect
                width={canvasWidth}
                height={canvasHeight}
                // fill='#ff00ff'
                stroke="#ff00ff" // Kolor ramki
                strokeWidth={10} // Grubość ramki
                dash={[80, 40]} // Styl przerywanej linii: 10px linii, 5px przerwy
              />
            </Group>
            {/* Clipped content */}
            <Group
              x={0}
              y={0}
              ref={artboardRef}
              width={canvasWidth}
              height={canvasHeight}
              clip={{
                x: 0,
                y: 0,
                width: canvasWidth,
                height: canvasHeight,
              }}
            >
              <Rect
                fill={backgroundColor}
                width={canvasWidth}
                height={canvasHeight}
                onClick={() => {
                  setSelectedShape(null);
                  setIsTransforming(false);
                }}
              />

              {canvasState.elements.map((element, index) =>
                element.type === "text" ? (
                  <Text
                    key={index}
                    x={element.x}
                    y={element.y}
                    text={element.text}
                    fontSize={element.size * (300 / 72)} // in points (pt)
                    fill={element.color}
                    draggable={activeTool === "Wybierz"}
                    onClick={(e) => {
                      if (activeTool === "Wybierz" && !isDragging) {
                        handleSelect(e.target);
                      }
                    }}
                    onTap={(e) => {
                      if (activeTool === "Wybierz" && !isDragging) {
                        handleSelect(e.target);
                      }
                    }}
                    onDragEnd={(e) => handleElementDragEnd(e, index)}
                    onTransformEnd={(e) => handleElementResize(e, index)}
                    scaleX={element.scaleX}
                    scaleY={element.scaleY}
                    rotation={element.rotation || 0}
                    fontFamily={element.font}
                    onContextMenu={(e) => handleContextMenu(e, index)}
                  />
                ) : element.type === "image" ? (
                  <KonvaImage
                    key={index}
                    image={element.image}
                    x={element.x}
                    y={element.y}
                    width={element.width}
                    height={
                      (element.width / element.image.width) *
                      element.image.height
                    }
                    draggable={activeTool === "Wybierz" && !isDragging}
                    onClick={(e) => {
                      if (activeTool === "Wybierz" && !isDragging)
                        handleSelect(e.target);
                    }}
                    onTap={(e) => {
                      if (activeTool === "Wybierz" && !isDragging)
                        handleSelect(e.target);
                    }}
                    onDragEnd={(e) => handleElementDragEnd(e, index)}
                    onTransformEnd={(e) => handleElementResize(e, index)}
                    scaleX={element.scaleX}
                    scaleY={element.scaleY}
                    rotation={element.rotation || 0}
                    onContextMenu={(e) => handleContextMenu(e, index)}
                  />
                ) : null
              )}

              {/* Frame */}
              {canvasState.frame && (
                <KonvaImage
                  x={0}
                  y={0}
                  width={canvasWidth || 0}
                  image={canvasState.frame}
                  listening={false}
                />
              )}

              {canvasState.name !== "Okładka" && (
                <Calendarium activePage={activePage} />
              )}

              {/* Calendarium */}
              {canvasState.name !== "Okładka" && (
                <Calendar
                  artboardSize={artboardSize}
                  calendarWidth={calendarWidth}
                  calendarY={calendarY}
                  canvasState={canvasState}
                  currentYear={currentYear}
                  calendarDayWidth={calendarDayWidth}
                  calendarDayHeight={calendarDayHeight}
                  daysArray={yearMonths[monthIndex]}
                  dayNames={dayNames}
                  setHolidayName={setHolidayName}
                  holidays={holidays}
                  setColor={setColor}
                  handleDayClick={handleDayClick}
                  isHoliday={isHoliday}
                  month={activePage}
                  activeTool={activeTool}
                />
              )}

              {canvasState.name === "Okładka" && (
                // <Text
                //   text={text}
                //   ref={textRef}
                //   x={textPosition.x}
                //   y={textPosition.y}
                //   scaleX={textScale.x}
                //   scaleY={textScale.y}
                //   fontSize={240}
                //   draggable
                //   fill={!isEditing ? "black" : "transparent"}
                //   onClick={(e) => {
                //     if (activeTool === "Wybierz" && !isDragging) {
                //       handleSelect(e.target);
                //     } else if (activeTool === "Tekst") {
                //       handleTextClick();
                //     }
                //   }}
                //   onTap={(e) => {
                //     if (activeTool === "Wybierz" && !isDragging) {
                //       handleSelect(e.target);
                //     } else if (activeTool === "Tekst") {
                //       handleTextClick();
                //     }
                //   }}
                //   onDragEnd={handleTextDragEnd2}
                //   onTransformEnd={handleTextResize2}
                // />
                <></>
              )}
              {canvasState.name === "Okładka" && (
                <>
                  <Group
                    draggable={false}
                    // center horizontally
                    x={canvasWidth / 3.5}
                    y={canvasHeight / 15}
                  >
                    <Text
                      text={"KALENDARZ"}
                      fill={"black"}
                      stroke="black"
                      strokeWidth={20}
                      fontSize={250}
                      x={20}
                      y={10}
                    />
                    <Text
                      text={"KALENDARZ"}
                      fill={"black"}
                      stroke="black"
                      strokeWidth={20}
                      fontSize={250}
                      x={0}
                      y={0}
                    />
                    <Text
                      text={"KALENDARZ"}
                      fill={"white"}
                      fontSize={250}
                      x={0}
                      y={0}
                    />
                  </Group>
                  <Group
                    draggable={false}
                    x={canvasWidth / 4.5}
                    y={(canvasHeight / 15) * 12}
                  >
                    <Text
                      text={"2025"}
                      fill={"black"}
                      stroke="black"
                      strokeWidth={20}
                      fontSize={900}
                      fontStyle="bold"
                      x={30}
                      y={20}
                    />
                    <Text
                      text={"2025"}
                      fill={"black"}
                      stroke="black"
                      strokeWidth={20}
                      fontSize={900}
                      fontStyle="bold"
                      x={0}
                      y={0}
                    />
                    <Text
                      text={"2025"}
                      fill={"white"}
                      fontSize={900}
                      fontStyle="bold"
                      x={0}
                      y={0}
                    />
                  </Group>
                </>
              )}
            </Group>
            {isTransforming && !isEditing && (
              <Transformer
                ref={transformerRef}
                boundBoxFunc={(oldBox, newBox) =>
                  handleTransform(newBox, oldBox)
                }
              />
            )}
          </Layer>
        </Stage>
      ) : (
        // Kubek
        <Stage
          width={containerRef.current ? containerRef.current.offsetWidth : 0}
          height={containerRef.current ? containerRef.current.offsetHeight : 0}
          style={{
            cursor:
              activeTool === "Pisak"
                ? "crosshair"
                : activeTool === "Tekst"
                ? "text"
                : activeTool === "Przesuń"
                ? "grab"
                : "default",
          }}
          ref={stageRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onTouchStart={handleMouseDown}
          onTouchMove={handleMouseMove}
          onTouchEnd={handleMouseUp}
          scaleX={scale}
          scaleY={scale}
          x={stagePosition.x}
          y={stagePosition.y}
          // listening={activeTool === "Przesuń"}
        >
          <Layer>
            {/* Clipped content */}
            <Group
              x={0}
              y={0}
              ref={artboardRef}
              width={canvasWidth}
              height={canvasHeight}
              clip={{
                x: 0,
                y: 0,
                width: canvasWidth,
                height: canvasHeight,
              }}
            >
              <Rect
                fill={backgroundColor}
                width={canvasWidth}
                height={canvasHeight}
                onClick={() => {
                  setSelectedShape(null);
                  setIsTransforming(false);
                }}
              />

              {canvasState.elements.map((element, index) =>
                element.type === "text" ? (
                  <Text
                    key={index}
                    x={element.x}
                    y={element.y}
                    text={element.text}
                    fontSize={element.size * (300 / 72)} // in points (pt)
                    fill={element.color}
                    draggable={activeTool === "Wybierz"}
                    onClick={(e) => {
                      if (activeTool === "Wybierz" && !isDragging) {
                        handleSelect(e.target);
                      }
                    }}
                    onTap={(e) => {
                      if (activeTool === "Wybierz" && !isDragging) {
                        handleSelect(e.target);
                      }
                    }}
                    onDragEnd={(e) => handleElementDragEnd(e, index)}
                    onTransformEnd={(e) => handleElementResize(e, index)}
                    scaleX={element.scaleX}
                    scaleY={element.scaleY}
                    rotation={element.rotation || 0}
                    fontFamily={element.font}
                    onContextMenu={(e) => handleContextMenu(e, index)}
                  />
                ) : element.type === "image" ? (
                  <KonvaImage
                    key={index}
                    image={element.image}
                    x={element.x}
                    y={element.y}
                    width={element.width}
                    height={
                      (element.width / element.image.width) *
                      element.image.height
                    }
                    draggable={activeTool === "Wybierz" && !isDragging}
                    onDragEnd={handleImageDragEnd}
                    onClick={(e) => {
                      if (activeTool === "Wybierz" && !isDragging)
                        handleSelect(e.target);
                    }}
                    onTap={(e) => {
                      if (activeTool === "Wybierz" && !isDragging)
                        handleSelect(e.target);
                    }}
                    onTransformEnd={handleImageResize}
                    scaleX={element.scaleX}
                    scaleY={element.scaleY}
                    rotation={element.rotation || 0}
                    onContextMenu={(e) => handleContextMenu(e, index)}
                  />
                ) : null
              )}
            </Group>
            {isTransforming && !isEditing && (
              <Transformer ref={transformerRef} />
            )}
          </Layer>
        </Stage>
      )}

      <input
        ref={inputRef}
        style={{
          display: "none",
          position: "absolute",
          fontSize: 240 * scale + "px",
          backgroundColor: "transparent",
          fontFamily: "sans-serif",
          lineHeight: 1,
          transform: `scale(${textScale.x}, ${textScale.y})`,
          transformOrigin: "left top",
          // color: "transparent",
        }}
        className="p-0 m-0 box-border"
        defaultValue={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
        onBlur={handleInputBlur}
      />

      <div className="flex gap-2 absolute bottom-2 right-2 rounded-md">
        <Zoom
          scale={scale}
          setScale={setScale}
          canvasHeight={canvasHeight}
          stageHeight={stageRef.current ? stageRef.current.height() : 0}
          stageWidth={stageRef.current ? stageRef.current.width() : 0}
          artboardHeight={artboardSize.height}
          artboardWidth={artboardSize.width}
          setStagePosition={setStagePosition}
          setIsZooming={setIsZooming}
          stagePosition={stagePosition}
        />
      </div>

      {contextMenu.visible && (
        <div
          style={{
            position: "absolute",
            top: contextMenu.y,
            left: contextMenu.x,
            border: "1px solid #ccc",
            zIndex: 1000,
          }}
          className="bg-white flex flex-col rounded-lg overflow-hidden shadow-lg"
        >
          <ContextMenuItem
            text="Przenieś na wierch"
            callback={handleMoveUp}
            icon={BsLayerForward}
          />
          <ContextMenuItem
            text="Przenieś pod spód"
            callback={handleMoveDown}
            icon={BsLayerBackward}
          />
          <ContextMenuItem
            text="Usuń"
            callback={handleDelete}
            icon={FaRegTrashAlt}
            iconSize={"0.9em"}
          />
        </div>
      )}
    </div>
  );
};

const ContextMenuItem = ({ text, callback, icon: Icon, iconSize }) => {
  return (
    <button
      className="px-1 py-2 pr-4 hover:bg-gray-100 text-left flex items-center rounded-lg"
      onClick={callback}
    >
      {Icon && (
        <Icon
          className="inline mx-2 w-4 text-center text-gray-500"
          size={iconSize || "1em"}
        />
      )}
      {text}
    </button>
  );
};

export default Canvas;
