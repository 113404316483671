export const holidays2025 = [
  {
    day: "1",
    month: "Styczeń",
    name: "Nowy Rok",
  },
  {
    day: "6",
    month: "Styczeń",
    name: "Trzech Króli",
  },
  {
    day: "21",
    month: "Styczeń",
    name: "Dzień Babci",
    color: "blue",
  },
  {
    day: "22",
    month: "Styczeń",
    name: "Dzień Dziadka",
    color: "blue",
  },
  {
    day: "26",
    month: "Styczeń",
    name: "Niedziela Handlowa",
  },
  {
    day: "14",
    month: "Luty",
    name: "Walentynki",
    color: "blue",
  },
  {
    day: "27",
    month: "Luty",
    name: "Tłusty Czwartek",
    color: "blue",
  },
  {
    day: "8",
    month: "Marzec",
    name: "Dzień Kobiet",
    color: "blue",
  },
  {
    day: "10",
    month: "Marzec",
    name: "Dzień Mężczyzn",
    color: "blue",
  },
  {
    day: "20",
    month: "Marzec",
    name: "Pierwszy Dzień Wiosny",
    color: "blue",
  },
  {
    day: "30",
    month: "Marzec",
    name: "Zmiana Czasu na Letni",
  },
  {
    day: "13",
    month: "Kwiecień",
    name: "Nd. Palmowa, Handlowa",
  },
  {
    day: "17",
    month: "Kwiecień",
    name: "Wielki Czwartek",
    color: "blue",
  },
  {
    day: "18",
    month: "Kwiecień",
    name: "Wielki Piątek",
    color: "blue",
  },
  {
    day: "19",
    month: "Kwiecień",
    name: "Wielka Sobota",
    color: "blue",
  },
  {
    day: "20",
    month: "Kwiecień",
    name: "Wielkanoc",
  },
  {
    day: "21",
    month: "Kwiecień",
    name: "Poniedziałek Wielkanocny",
  },
  {
    day: "27",
    month: "Kwiecień",
    name: "Niedziela Handlowa",
  },
  {
    day: "1",
    month: "Maj",
    name: "Święto Pracy",
  },
  {
    day: "3",
    month: "Maj",
    name: "Konstytucja 3 Maja",
  },
  {
    day: "26",
    month: "Maj",
    name: "Dzień Matki",
    color: "blue",
  },
  {
    day: "1",
    month: "Czerwiec",
    name: "Dzień Dziecka",
  },
  {
    day: "8",
    month: "Czerwiec",
    name: "Zesłanie Ducha Świętego",
  },
  {
    day: "19",
    month: "Czerwiec",
    name: "Boże Ciało",
  },
  {
    day: "21",
    month: "Czerwiec",
    name: "Pierwszy Dzień Lata",
    color: "blue",
  },
  {
    day: "23",
    month: "Czerwiec",
    name: "Dzień Ojca",
    color: "blue",
  },
  {
    day: "29",
    month: "Czerwiec",
    name: "Niedziela Handlowa",
  },
  {
    day: "15",
    month: "Sierpień",
    name: "Wniebowzięcie Najświętszej Maryi Panny",
  },
  {
    day: "31",
    month: "Sierpień",
    name: "Niedziela Handlowa",
  },
  {
    day: "22",
    month: "Wrzesień",
    name: "Pierwszy Dzień Jesieni",
    color: "blue",
  },
  {
    day: "30",
    month: "Wrzesień",
    name: "Dzień Chłopaka",
    color: "blue",
  },
  {
    day: "14",
    month: "Październik",
    name: "Dzień Nauczyciela",
    color: "blue",
  },
  {
    day: "26",
    month: "Październik",
    name: "Zmiana Czasu na Zimowy",
  },
  {
    day: "1",
    month: "Listopad",
    name: "Wszystkich Świętych",
  },
  {
    day: "11",
    month: "Listopad",
    name: "Święto Niepodległości",
  },
  {
    day: "29",
    month: "Listopad",
    name: "Andrzejki",
    color: "blue",
  },
  {
    day: "14",
    month: "Grudzień",
    name: "Niedziela Handlowa",
  },
  {
    day: "21",
    month: "Grudzień",
    name: "Pierwszy Dzień Zimy, Niedziela Handlowa",
  },
  {
    day: "24",
    month: "Grudzień",
    name: "Wigilia Bożego Narodzenia",
    color: "blue",
  },
  {
    day: "25",
    month: "Grudzień",
    name: "Boże Narodzenie (pierwszy dzień)",
  },
  {
    day: "26",
    month: "Grudzień",
    name: "Boże Narodzenie (drugi dzień)",
  },
  {
    day: "31",
    month: "Grudzień",
    name: "Sylwester",
    color: "blue",
  },
];
