import React from "react";

const Checkmark = ({ size = 56 }) => {
  const keyframesStroke = `
  @keyframes stroke {
    100% { stroke-dashoffset: 0; }
  }
`;

  const keyframesScale = `
  @keyframes scale {
    0%, 100% { transform: none; }
    50% { transform: scale3d(1.1, 1.1, 1); }
  }
`;

  const keyframesFill = `
  @keyframes fill {
    100% { box-shadow: inset 0px 0px 0px ${size}px #7ac142; }
  }
`;

  const styleSheet = document.styleSheets[0];

  // Add keyframes to the document
  styleSheet.insertRule(keyframesStroke, styleSheet.cssRules.length);
  styleSheet.insertRule(keyframesScale, styleSheet.cssRules.length);
  styleSheet.insertRule(keyframesFill, styleSheet.cssRules.length);

  return (
    <div style={{ ...styles.wrapper, width: size, height: size }}>
      <svg
        style={{ ...styles.checkmark, width: size, height: size }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle
          style={styles.checkmarkCircle}
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          style={styles.checkmarkCheck}
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
    </div>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    margin: 0,
  },
  checkmark: {
    width: "56px",
    height: "56px",
    borderRadius: "50%",
    display: "block",
    strokeWidth: "2",
    stroke: "#fff",
    strokeMiterlimit: "10",
    margin: "10% auto",
    boxShadow: "inset 0px 0px 0px #7ac142",
    animation:
      "fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both",
  },
  checkmarkCircle: {
    strokeDasharray: "166",
    strokeDashoffset: "166",
    strokeWidth: "2",
    strokeMiterlimit: "10",
    stroke: "#7ac142",
    fill: "none",
    animation: "stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards",
  },
  checkmarkCheck: {
    transformOrigin: "50% 50%",
    strokeDasharray: "48",
    strokeDashoffset: "48",
    animation: "stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards",
  },
};

export default Checkmark;
