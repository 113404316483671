import React, { useContext } from "react";

import { AppContext } from "../AppContext";

// Icons
import { FaRegImages } from "react-icons/fa";
import { MdOutlineFilterFrames } from "react-icons/md";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import { TbMessageReportFilled } from "react-icons/tb";
import { IoText } from "react-icons/io5";
import { FaGear } from "react-icons/fa6";
import { FiLayers } from "react-icons/fi";

import logo from "../assets/logo-01.png";
import { Link } from "react-router-dom";

const Button = ({
  datatip,
  activePane,
  setActivePane,
  className,
  icon: Icon,
  href,
  color,
  ...props
}) => {
  const buttonContent = (
    <button
      className={`text-gray-500 rounded-md w-12 aspect-square p-2 border border-1 border-gray-300 hover:opacity-80 bg-gray-100 flex items-center justify-center ${
        activePane === datatip ? "text-primary border-2 border-primary" : null
      }`}
      onClick={() =>
        setActivePane && activePane === datatip
          ? setActivePane(null)
          : setActivePane(datatip)
      }
      style={{ width: "3rem", height: "3rem", padding: 0 }}
    >
      {Icon && <Icon size="1.5em" color={color} />}
      {props.children}
    </button>
  );

  return (
    <div className={`tooltip tooltip-right ${className}`} data-tip={datatip}>
      {href ? (
        <a href={href} target="_blank" rel="noreferrer">
          {buttonContent}
        </a>
      ) : (
        buttonContent
      )}
    </div>
  );
};

const Navigation = () => {
  const { setActivePane, activePane, productType } = useContext(AppContext);
  return (
    <div className="flex flex-col gap-2 p-2 items-center bg-gray-200">
      <div
        className="tooltip tooltip-right z-10"
        data-tip="SpokoDruczki.pl - Stwórzmy coś Spoko"
      >
        <img
          src={logo}
          alt="Logo"
          className="w-12 h-12 hover:opacity-80 duration-100 "
        />
      </div>
      <Button
        datatip="Zdjęcia"
        setActivePane={setActivePane}
        activePane={activePane}
        icon={FaRegImages}
      />
      {productType === "Kalendarz" && (
        <Button
          datatip="Ramki"
          setActivePane={setActivePane}
          activePane={activePane}
          icon={MdOutlineFilterFrames}
        />
      )}
      <Button
        datatip="Tekst"
        setActivePane={setActivePane}
        activePane={activePane}
        icon={IoText}
      />
      <Button
        datatip="Warstwy"
        setActivePane={setActivePane}
        activePane={activePane}
        icon={FiLayers}
        className={"mb-auto"}
      />
      {/* <Button
        datatip="Ustawienia"
        setActivePane={setActivePane}
        activePane={activePane}
        icon={FaGear}
        /> */}
      <Button
        className="mt-auto"
        datatip="Zgłoś błąd"
        icon={TbMessageReportFilled}
        href="mailto:prodej.pl+spokodruczki@gmail.com"
        target="_blank"
        color="#aa3333"
      />
    </div>
  );
};

export default Navigation;
