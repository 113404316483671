import React, { useContext } from "react";
import { AppContext } from "../AppContext";
import { useParams } from "react-router-dom";

// Icons
import { FaRegHandPaper } from "react-icons/fa";
import { RxCursorArrow } from "react-icons/rx";
import { GiHighlighter } from "react-icons/gi";
import { CgUndo } from "react-icons/cg";
import { CgRedo } from "react-icons/cg";
import { PiTextT } from "react-icons/pi";

const ToolsButton = ({
  datatip,
  activeTool,
  setActiveTool,
  className,
  ...props
}) => {
  return (
    <div className={`tooltip tooltip-bottom ${className}`} data-tip={datatip}>
      <button
        className={`text-gray-500 rounded-md w-12 aspect-square p-2 border border-1 border-gray-300 hover:opacity-80 bg-gray-100 flex items-center justify-center ${
          datatip === activeTool ? "text-primary border-2 border-primary" : null
        }`}
        onClick={() => setActiveTool(datatip)}
      >
        {props.children}
      </button>
    </div>
  );
};

const Tools = ({ stageRef }) => {
  const { activeTool, setActiveTool, undo, redo, handleExport, deleteCode } =
    useContext(AppContext);

  const handleSaveClick = () => {
    if (
      window.confirm(
        "Po zapisaniu pliku nie bedziesz mógł już wprowadzić żadnych zmian. Czy na pewno zakończyłeś/aś edycję?"
      )
    ) {
      handleExport(stageRef);
    }
  };

  return (
    <div className="flex items-center p-2 shadow-lg gap-2 bg-gray-200 border border-1 border-b-gray-300">
      <ToolsButton
        datatip="Przesuń"
        activeTool={activeTool}
        setActiveTool={setActiveTool}
      >
        <FaRegHandPaper size={"1.6em"} />
      </ToolsButton>
      <ToolsButton
        datatip="Wybierz"
        activeTool={activeTool}
        setActiveTool={setActiveTool}
      >
        <RxCursorArrow size={"1.6em"} />
      </ToolsButton>
      {/* 
      <ToolsButton
        datatip="Tekst"
        activeTool={activeTool}
        setActiveTool={setActiveTool}
      >
        <PiTextT size={"1.6em"} />
      </ToolsButton> */}
      {/* <ToolsButton
        datatip='Pisak'
        activeTool={activeTool}
        setActiveTool={setActiveTool}
      >
        <GiHighlighter size={'1.6em'} />
      </ToolsButton> */}
      {/* <div className="ml-auto tooltip tooltip-bottom" data-tip={"Cofnij"}>
        <button className="btn btn-neutral p-2 min-w-12" onClick={undo}>
          <CgUndo size={"1.6em"} />
        </button>
      </div>
      <div className="tooltip tooltip-bottom" data-tip={"Ponów"}>
        <button className="btn btn-neutral p-2 min-w-12" onClick={redo}>
          <CgRedo size={"1.6em"} />
        </button>
      </div> */}
      <div className="tooltip tooltip-bottom ml-auto" data-tip={"Zapisz"}>
        <button
          className="btn btn-primary text-white"
          onClick={handleSaveClick}
        >
          Zapisz
        </button>
      </div>
    </div>
  );
};

export default Tools;
