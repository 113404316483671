import React from "react";
import Checkmark from "../components/Checkmark";

const Success = () => {
  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      <Checkmark size={150} />
      <p className="mt-12 text-lg">
        Projekt Twojego kalendarza jest już w naszych rękach i został przekazany
        do wydruku.
      </p>
      <p className="mt-2 text-lg">Dziękujemy za zaufanie :)</p>
    </div>
  );
};

export default Success;
