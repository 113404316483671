import React, { useState } from "react";

const Settings = () => {
  const [color, setColor] = useState({
    r: "241",
    g: "112",
    b: "19",
    a: "1",
  });

  const styles = {
    color: {
      width: "34px",
      height: "14px",
      borderRadius: "2px",
      background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
    },
    swatch: {
      padding: "5px",
      background: "#fff",
      borderRadius: "1px",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      display: "inline-block",
      cursor: "pointer",
    },
    popover: {
      position: "absolute",
      zIndex: "2",
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  };

  return (
    <div className="flex flex-col gap-4">
      <h1 className="pb-4 text-center">Settings:</h1>
      <div className="flex items-center">
        <div className="flex flex-1 lin">Kolor świąt ustawowo wolnych</div>
        <div className="flex h-min">
          <div style={styles.swatch} onClick={() => alert("klik")}>
            <div style={styles.color} />
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex flex-1">kolor pozostałych świąt</div>
        <div className="flex h-min">
          <div style={styles.swatch} onClick={() => alert("klik")}>
            <div style={styles.color} />
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex flex-1">kolor tła</div>
        <div className="flex h-min">
          <div style={styles.swatch} onClick={() => alert("klik")}>
            <div style={styles.color} />
          </div>
        </div>
      </div>
      <div className="flex items-center">
        <button className="btn">Usuń wszystkie święta</button>
      </div>
      <div className="flex items-center">
        <button className="btn">Przywróć domyślne święta</button>
      </div>
    </div>
  );
};

export default Settings;
