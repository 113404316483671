import React from "react";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { MdPhone } from "react-icons/md";
import { IoIosMail } from "react-icons/io";
import { IoMdHappy } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { Carousel, Flex, Layout, Input } from "antd";
import { IoSearchSharp } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { LuShoppingCart } from "react-icons/lu";

// import "swiper/swiper-bundle.css";

const { Header, Sider, Content } = Layout;
const { Search } = Input;

const onSearch = (value, _e, info) => console.log(info?.source, value);

const Home = () => {
  return (
    // <div className="flex flex-col w-svw h-svw pt-32">
    // {/* <Navbar /> */}
    // {/* <Hero /> */}
    // {/* <Products /> */}
    // {/* <Recommentations /> */}
    // {/* <Portfolio /> */}
    // {/* <Graficy /> */}
    // {/* <About /> */}
    // {/* <Blog /> */}
    // {/* <Newsletter /> */}
    // {/* <Footer /> */}
    // </div>
    <Layout>
      <Flex justify="center">
        <div className="container">
          <Flex align="center">
            <MdPhone />
            (+48) 123-123-123
            <IoIosMail />
          </Flex>
        </div>
      </Flex>
      <Flex justify="center">
        <div className="container">
          <Flex
            align="center"
            justify="space-between"
            className="border border-black min-h-48"
          >
            <div className="w-56">
              <div className="relative w-fit flex items-center">
                <input
                  type="text"
                  placeholder="Znajdź produkt"
                  className="p-2 pl-4 pr-8 rounded-full outline-[#cccccc] shadow-sm"
                />
                <IoSearchSharp
                  size={"1.5em"}
                  color="#cccccc"
                  className="absolute right-2"
                />
              </div>
            </div>
            <Logo />
            <div className="w-56">
              <Flex justify="flex flex-end">
                <Flex
                  vertical
                  align="center"
                  justify="center"
                  className="rounded-full bg-gray-100 shadow-sm border border-red-500"
                >
                  <FaRegUser size="2rem" color="gray" />
                  <span>Konto</span>
                </Flex>
                <Flex vertical align="center" justify="center">
                  <LuShoppingCart size="2rem" color="gray" />
                  <span>Koszyk</span>
                </Flex>
              </Flex>
            </div>
          </Flex>
        </div>
      </Flex>
      <nav className="flex justify-center bg-gray-200 py-2">
        <div className="container">
          <Flex align="center">
            <div className="flex flex-1">Left</div>
            <Flex className="gap-4">
              <div>Right</div>
            </Flex>
          </Flex>
        </div>
      </nav>
      <Content>
        <Flex vertical align="stretch">
          <Carousel arrows infinite autoplay autoplaySpeed={10000}>
            <div className="w-full h-96 bg-gray-500">1</div>
            <div className="w-full h-96 bg-gray-500">2</div>
          </Carousel>
        </Flex>
      </Content>
      <Footer />
    </Layout>
  );
};

const Logo = ({ className }) => {
  return (
    <span
      className={`text-2xl font-bold px-2 py-1 bg-red-500 text-white rounded-md uppercase ${className}`}
    >
      SpokoDruczki
    </span>
  );
};

const Footer = () => {
  const FooterSection = ({ title, children }) => {
    return (
      <Flex vertical className="flex-1">
        <div className="w-fit relative mb-2">
          <h2 className="text-3xl font-bold relative z-10 mb-2">{title}</h2>
          <div className="bg-red-500 w-full h-1 absolute bottom-0 rounded-lg"></div>
        </div>
        {children}
      </Flex>
    );
  };

  return (
    <footer className="bg-gray-200 py-2">
      <Flex vertical align="center">
        <div className="container">
          <Flex className="py-8">
            <FooterSection title="O nas">
              <ul className="flex flex-col gap-2 font-medium">
                <li>SpokoDruczki.pl</li>
                <li>Unii Europejskiej 22</li>
                <li>32-600 Oświęcim</li>
                <li>Polska</li>
                <li>biuro@spokodruczki.pl</li>
                <li>(+48) 123-123-123</li>
              </ul>
            </FooterSection>
            <FooterSection title="O nas">
              <ul className="flex flex-col gap-2 font-medium">
                <li>SpokoDruczki.pl</li>
                <li>Unii Europejskiej 22</li>
                <li>32-600 Oświęcim</li>
                <li>Polska</li>
                <li>biuro@spokodruczki.pl</li>
                <li>(+48) 123-123-123</li>
              </ul>
            </FooterSection>
            <FooterSection title="O nas">
              <ul className="flex flex-col gap-2 font-medium">
                <li>SpokoDruczki.pl</li>
                <li>Unii Europejskiej 22</li>
                <li>32-600 Oświęcim</li>
                <li>Polska</li>
                <li>biuro@spokodruczki.pl</li>
                <li>(+48) 123-123-123</li>
              </ul>
            </FooterSection>
            <FooterSection title="O nas">
              <ul className="flex flex-col gap-2 font-medium">
                <li>SpokoDruczki.pl</li>
                <li>Unii Europejskiej 22</li>
                <li>32-600 Oświęcim</li>
                <li>Polska</li>
                <li>biuro@spokodruczki.pl</li>
                <li>(+48) 123-123-123</li>
              </ul>
            </FooterSection>
          </Flex>
          <hr className="border-gray-300" />
          <Flex justify="center" className="py-4">
            Spokodruczki.pl © 2024. Wszelkie prawa zastrzeżone
          </Flex>
        </div>
      </Flex>
    </footer>
  );
};

// const Navbar = () => {
//   return (
//     <div className="flex flex-col fixed top-0 w-full z-50">
//       <div className="bg-gray-200 flex justify-center">
//         <div className="container py-1">
//           <ul className="flex gap-4">
//             <li className="flex items-center gap-2">
//               <MdPhone />
//               (+48) 123-123-123
//             </li>
//             <li className="flex items-center gap-2">
//               <IoIosMail />
//               kontakt@spokodruczki.pl
//             </li>
//             <li className="flex items-center gap-2">
//               <IoMdHappy />
//               Obsługa klienta/Infolinia dostępna w godzinach 8:00-13:00
//             </li>
//             <li className="flex items-center gap-2 ml-auto">
//               <Link to="/login">Zaloguj</Link>
//               <Link to="/logout">Wyloguj</Link>
//             </li>
//           </ul>
//         </div>
//       </div>
//       <div className="bg-gray-100 flex justify-center">
//         <div className="container h-24 flex items-center justify-between">
//           <div className="w-40 h-14 grid place-items-center bg-red-500 rounded-md text-3xl font-bold text-white">
//             SPOKO
//           </div>
//           <ul className="flex gap-8">
//             <li className="uppercase font-medium">
//               <a href="#">Produkty</a>
//             </li>
//             <li className="uppercase font-medium">
//               <a href="#">Produkty Indywidualne</a>
//             </li>
//             <li className="uppercase font-medium">
//               <a href="#">Zamów</a>
//             </li>
//             <li className="uppercase font-medium">
//               <a href="#">O nas</a>
//             </li>
//             <li className="uppercase font-medium">
//               <a href="#">B2B</a>
//             </li>
//             <li className="uppercase font-medium">
//               <a href="#">Kontakt</a>
//             </li>
//             <li className="relative">
//               <FaShoppingCart size={"1.6em"} className="text-gray-700" />
//               <div className="bg-white rounded-full shadow-black shadow-sm flex items-center justify-center absolute h-5 text-sm aspect-square -right-3 -bottom-2">
//                 0
//               </div>
//               <div className="shadow-lg absolute bg-white min-w-64 -right-2 rounded-lg">
//                 <h2 className="font-bold text-gray-400 text-center py-1 text-lg">
//                   Twój koszyk:
//                 </h2>
//                 <div className="p-2">Kalendarz 13-stronny A4</div>
//                 <div className="p-2">Kubek Biały Premium</div>
//                 <div className="p-2">T-shirt Biały</div>
//               </div>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// };

// const Hero = () => {
//   return (
//     <div className="flex justify-center">
//       <div className="container min-h-96">
//         <Swiper
//           // spaceBetween={50}
//           slidesPerView={1}
//           onSlideChange={() => console.log("slide change")}
//           onSwiper={(swiper) => console.log(swiper)}
//           modules={[Navigation, Pagination]}
//           navigation
//           pagination={{ clickable: true }}
//           className="bg-gray-200 h-96 select-none"
//         >
//           <SwiperSlide>Slide 1</SwiperSlide>
//           <SwiperSlide>Slide 2</SwiperSlide>
//           <SwiperSlide>Slide 3</SwiperSlide>
//           <SwiperSlide>Slide 4</SwiperSlide>
//         </Swiper>
//       </div>
//     </div>
//   );
// };

// const Products = () => {
//   return (
//     <div className="flex justify-center py-32">
//       <div className="container">
//         <h2 className="text-3xl font-bold mb-4">Produkty</h2>
//         <div className="flex">
//           <div className="min-w-96">
//             <h2 className="font-bold border-b border-b-gray-200 mb-1 pb-1 mr-4">
//               KATEGORIE
//             </h2>
//             <ul className="flex flex-col gap-2">
//               <li>Kalendarze</li>
//               <li className="bg-black text-white ml-2 mr-4 rounded-lg px-2 font-medium py-1">
//                 Kubki
//               </li>
//               <li>Ubrania</li>
//               <li>Wizytówki</li>
//               <li>Gadżety</li>
//             </ul>
//           </div>
//           <div className="flex flex-wrap gap-4">
//             <div className="w-48 h-48 rounded-md border border-1-gray-300 flex flex-col items-center justify-center text-center p-4">
//               <h2 className="font-bold text-lg">Kalendarze 13-stronne</h2>
//               <span>Kalendarze 13-stronne na rok 2025</span>
//             </div>
//             <div className="w-48 h-48 rounded-md border border-1-gray-300 flex flex-col items-center justify-center text-center p-4">
//               <h2 className="font-bold text-lg">Kalendarze plakatowe</h2>
//               <span>Kalendarze plakatowe na rok 2025</span>
//             </div>
//             <div className="w-48 h-48 rounded-md border border-1-gray-300 flex flex-col items-center justify-center text-center p-4">
//               <h2 className="font-bold text-lg">Kalendarze trójdzielne</h2>
//               <span>Kalendarze trójdzielne na rok 2025</span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const Portfolio = () => {
//   return (
//     <div className="flex justify-center  py-16">
//       <div className="container flex flex-col gap-4">
//         <h2 className="font-bold text-3xl">Realizacje</h2>
//         <div className="flex gap-4 justify-left flex-wrap">
//           <div className="w-80 h-80 bg-gray-600">asd</div>
//           <div className="w-80 h-80 bg-gray-600">asd</div>
//           <div className="w-80 h-80 bg-gray-600">asd</div>
//           <div className="w-80 h-80 bg-gray-600">asd</div>
//           <div className="w-80 h-80 bg-gray-600">asd</div>
//           <div className="w-80 h-80 bg-gray-600">asd</div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const Blog = () => {
//   return (
//     <div className="flex justify-center py-16 bg-gray-200">
//       <div className="container flex flex-col">
//         <h2 className="text-3xl font-bold text-center mb-8">Blog</h2>
//         <div className="flex gap-4 justify-center">
//           <div className="flex flex-col">
//             <div className="w-48 h-32 bg-gray-500"></div>
//             <h3 className="font-bold">Lorem Ipsum</h3>
//             <p className="max-w-48">
//               Lorem ipsum sit dolor ames aldskj aslkdj aslkj alksj alkj.
//             </p>
//           </div>
//           <div className="flex flex-col">
//             <div className="w-48 h-32 bg-gray-500"></div>
//             <h3 className="font-bold">Lorem Ipsum</h3>
//             <p className="max-w-48">
//               Lorem ipsum sit dolor ames aldskj aslkdj aslkj alksj alkj.
//             </p>
//           </div>
//           <div className="flex flex-col">
//             <div className="w-48 h-32 bg-gray-500"></div>
//             <h3 className="font-bold">Lorem Ipsum</h3>
//             <p className="max-w-48">
//               Lorem ipsum sit dolor ames aldskj aslkdj aslkj alksj alkj.
//             </p>
//           </div>
//           <div className="flex flex-col">
//             <div className="w-48 h-32 bg-gray-500"></div>
//             <h3 className="font-bold">Lorem Ipsum</h3>
//             <p className="max-w-48">
//               Lorem ipsum sit dolor ames aldskj aslkdj aslkj alksj alkj.
//             </p>
//           </div>
//           <div className="flex flex-col">
//             <div className="w-48 h-32 bg-gray-500"></div>
//             <h3 className="font-bold">Lorem Ipsum</h3>
//             <p className="max-w-48">
//               Lorem ipsum sit dolor ames aldskj aslkdj aslkj alksj alkj.
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const Newsletter = () => {
//   return (
//     <div className="flex justify-center py-16 bg-gray-300">
//       <div className="container text-center flex flex-col items-center gap-6">
//         <h2 className="text-3xl font-bold mb-2">Newsletter</h2>
//         <p className="max-w-xl">
//           Chcesz być na bieżąco z nowościami? Podaj nam mail, a będziesz
//           otrzymywać informacje o promocjach i nowych produktach. Obiecujemy,
//           nie wysyłać spamu :)
//         </p>
//         <div className="flex">
//           <input
//             type="text"
//             className="text border border-gray-300 bg-white rounded-lg px-4 py-2"
//             placeholder="Wpisz swój adres e-mail"
//           />
//           <button className="bg-black text-white font-medium uppercase py-4 px-4 rounded-lg ml-2">
//             Zapisuję się!
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// const Recommentations = () => {
//   return (
//     <div className="flex justify-center py-16 bg-gray-200">
//       <div className="container flex flex-col items-center">
//         <h2 className="text-3xl font-bold text-center text-gray-500 mb-8">
//           Zaufali nam
//         </h2>
//         <div className="flex gap-4">
//           <div>asd</div>
//           <div>asd</div>
//           <div>asd</div>
//           <div>asd</div>
//           <div>asd</div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const Graficy = () => {
//   return (
//     <div className="flex justify-center bg-gray-900 text-white py-16">
//       <div className="container flex flex-col">
//         <span>Nie masz projektu?</span>
//         <h2 className="font-bold text-3xl">
//           Skorzystaj z pomocy naszych grafików
//         </h2>
//         <button className="w-fit bg-white text-black font-medium uppercase py-4 px-4 rounded-lg mt-8">
//           Jestem zainteresowany
//         </button>
//       </div>
//     </div>
//   );
// };

// const About = () => {
//   return (
//     <div className="flex justify-center py-32">
//       <div className="container flex">
//         <div className="flex flex-1 relative">
//           <div className="w-64 h-48 bg-gray-600 absolute right-72 top-0"></div>
//           <div className="w-64 h-48 bg-gray-200 absolute right-48 top-8"></div>
//         </div>
//         <div className="flex flex-col flex-1 gap-8">
//           <h2 className="text-5xl font-bold relative w-fit">
//             O nas
//             <span className="absolute w-full bg-yellow-200 content-none h-4 left-0 bottom-0 -z-10"></span>
//           </h2>
//           <p>
//             Lorem Ipsum is simply dummy text of the printing and typesetting
//             industry. Lorem Ipsum has been the industry's standard dummy text
//             ever since the 1500s, when an unknown printer took a galley of type
//             and scrambled it to make a type specimen book. It has survived not
//             only five centuries, but also the leap into electronic typesetting,
//             remaining essentially unchanged. It was popularised in the 1960s
//             with the release of Letraset sheets containing Lorem Ipsum passages,
//             and more recently with desktop publishing software like Aldus
//             PageMaker including versions of Lorem Ipsum.
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// const Footer = () => {
//   return (
//     <>
//       <div className="flex justify-center text-white bg-gray-700 py-24">
//         <div className="container flex">
//           <div className="flex flex-1 pr-12">
//             <ul className="flex flex-col gap-2">
//               <li className="text-3xl font-bold">LOGO</li>
//               <li className="pr-24">
//                 Lorem Ipsum Sit Dolor Amet Lorem Ipsum Sit Dolor Amet Lorem
//                 Ipsum Sit Dolor Amet.
//               </li>
//             </ul>
//           </div>
//           <div className=" flex flex-1">
//             <ul className="flex flex-col">
//               <h2 className="uppercase font-medium">Lorem</h2>
//               <li>s</li>
//               <li>d</li>
//               <li>f</li>
//             </ul>
//           </div>
//           <div className=" flex flex-1">
//             <ul className="flex flex-col ">
//               <h2 className="uppercase font-medium">Lorem</h2>
//               <li>s</li>
//               <li>d</li>
//               <li>f</li>
//             </ul>
//           </div>
//           <div className=" flex flex-1">
//             <ul className="flex flex-col ">
//               <h2 className="uppercase font-medium">Lorem</h2>
//               <li>s</li>
//               <li>d</li>
//               <li>f</li>
//             </ul>
//           </div>
//         </div>
//       </div>
//       <div className="flex text-white bg-gray-700 justify-center">
//         <div className="container border-t border-t-gray-600 flex items-center justify-center py-8">
//           SpokoDruczki.pl © 2024. Wszelkie prawa zastrzeżone
//         </div>
//       </div>
//     </>
//   );
// };

export default Home;
