import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./screens/ErrorPage";
import Success from "./screens/Success";
import Home from "./screens/Home";
import Admin from "./screens/Admin";
import Account from "./screens/Account";
import Cart from "./screens/Cart";
import Login from "./screens/Login";
import Orders from "./screens/Orders";
import Register from "./screens/Register";
import Users from "./screens/Users";
import User from "./screens/User";
import PrivateRoute from "./components/PrivateRoute";

const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
const accountType = localStorage.getItem("accountType");

const router = createBrowserRouter([
  {
    path: "/",
    element: <Success />,
    errorElement: <ErrorPage />,
  },
  {
    path: "success",
    element: <Success />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "admin",
  //   element: <Admin />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "account",
  //   element: <Account />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "cart",
  //   element: <Cart />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "login",
  //   element: <Login />,
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "orders",
  //   element: (
  //     <PrivateRoute
  //       element={<Orders />}
  //       isLoggedIn={isLoggedIn}
  //       accountType={accountType}
  //       requiredAccountType="admin"
  //     />
  //   ),
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "users",
  //   element: (
  //     <PrivateRoute
  //       element={<Users />}
  //       isLoggedIn={isLoggedIn}
  //       accountType={accountType}
  //       requiredAccountType="admin"
  //     />
  //   ),
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "user",
  //   element: (
  //     <PrivateRoute
  //       element={<User />}
  //       isLoggedIn={isLoggedIn}
  //       accountType={accountType}
  //       requiredAccountType="admin"
  //     />
  //   ),
  //   errorElement: <ErrorPage />,
  // },
  // {
  //   path: "register",
  //   element: <Register />,
  //   errorElement: <ErrorPage />,
  // },
  {
    path: "generator/:id",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "kalendarz-13-stronny/:id",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "error",
    element: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
