import React, { createContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import JsBarcode from "jsbarcode";

import { holidays2025 } from "./holidays";

import axios from "axios";

export const AppContext = createContext();

const icons = {
  heart: { code: "\uf004", fontFamily: "Font Awesome" },
  star: { code: "\uf005", fontFamily: "Font Awesome" },
  cake: { code: "\uf1fd", fontFamily: "Font Awesome" },
  crown: { code: "\uf521", fontFamily: "Font Awesome" },
  circle: { code: "\uf111", fontFamily: "Font Awesome" },
  square: { code: "\uf0c8", fontFamily: "Font Awesome" },
  present: { code: "\uf79c", fontFamily: "Font Awesome" },
  tree: { code: "\uf1bb", fontFamily: "Font Awesome" },
  wine: { code: "\uf4e3", fontFamily: "Font Awesome" },
  pie: { code: "\uf705", fontFamily: "Font Awesome" },
  graduate: { code: "\uf19d", fontFamily: "Font Awesome" },
  music: { code: "\uf001", fontFamily: "Font Awesome" },
  wedding: { code: "\uf81b", fontFamily: "Font Awesome" },
  flower: { code: "\uf801", fontFamily: "Font Awesome" },
  suit: { code: "\uf508", fontFamily: "Font Awesome" },
};

export const AppProvider = ({ children }) => {
  const { id } = useParams();
  const barcodeRef = useRef(null);
  const [userdata, setUserdata] = useState(null);

  const fullSizeRefs = useRef([]);
  // Initialize refs for 13 pages
  if (fullSizeRefs.current.length !== 13) {
    fullSizeRefs.current = Array(13)
      .fill()
      .map((_, i) => fullSizeRefs.current[i] || React.createRef());
  }

  // Function to generate barcode
  const generateBarcode = (id) => {
    JsBarcode(barcodeRef.current, id, {
      format: "CODE128",
      displayValue: true,
      fontSize: 18,
      height: 40,
      width: 5,
      textPosition: "top",
      background: "#ffffff",
    });
  };

  // Kalendarze
  const A3_WIDTH = (297 / 25.4) * 300; // A3 width in px
  const A3_HEIGHT = (420 / 25.4) * 300; // A3 height in px

  // Kubki
  const CUP_WIDTH = (188 / 25.4) * 300;
  const CUP_HEIGHT = (96 / 25.4) * 300;

  const [calendar, setCalendar] = useState([]);

  const months = [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień",
  ];

  const rotateMonths = (startMonth) => {
    const startIndex = months.indexOf(startMonth);
    if (startIndex === -1) {
      throw new Error("Invalid month name");
    }

    const rotatedMonths = months
      .slice(startIndex)
      .concat(months.slice(0, startIndex));
    return rotatedMonths;
  };

  const pages = ["Okładka", ...rotateMonths("Styczeń")];

  // State
  const [images, setImages] = useState([]);
  const [showLeftPane, setShowLeftPane] = useState(true);
  const [showBottomPane, setShowBottomPane] = useState(true);
  const [frame, setFrame] = useState(null);
  const [PDFPages, setPDFPages] = useState(Array(13).fill(null));
  const [color, setColor] = useState();
  const [holidayName, setHolidayName] = useState("");
  const [holidayIcon, setHolidayIcon] = useState("");
  const [selectedDay, setSelectedDay] = useState(null);
  const [activePage, setActivePage] = useState(pages[0]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [activePane, setActivePane] = useState("Zdjęcia");
  const [activeTool, setActiveTool] = useState("Wybierz");
  const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });
  const [canvasStates, setCanvasStates] = useState(
    pages.reduce((acc, page) => {
      acc[page] = {
        name: `${page}`,
        image: null,
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        scaleX: 1,
        scaleY: 1,
        rotation: 0,
        elements: [],
        frame: null,
        calendarium: `assets/calendariums/${page}.png`,
      };
      return acc;
    }, {})
  );
  const [isTransforming, setIsTransforming] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [canvasThumbnails, setCanvasThumbnails] = useState({});
  const [holidays, setHolidays] = useState(holidays2025);
  const [scale, setScale] = useState(1);
  const [history, setHistory] = useState([]);
  const [redoHistory, setRedoHistory] = useState([]);
  const [selectedShape, setSelectedShape] = useState(null);
  const [selectedFrame, setSelectedFrame] = useState(null);
  const [productType, setProductType] = useState();

  const [calendarDays, setCalendarDays] = useState([]);
  const monthIndex = months.indexOf(activePage);
  const currentYear = 2025;
  // Utility function to generate a 2D array of days for a given month
  const generateCalendarDays = (month, year) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    let firstDayOfMonth = new Date(year, month, 1).getDay(); // Day of the week (0 = Sunday)
    // Adjust to make Monday the first day of the week (0 = Monday, 6 = Sunday)
    firstDayOfMonth = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

    const weeks = [];
    let week = new Array(7).fill(null); // Start with an empty week

    for (let i = 1; i <= daysInMonth; i++) {
      const dayOfWeek = (firstDayOfMonth + i - 1) % 7;

      week[dayOfWeek] = i;

      if (dayOfWeek === 6 || i === daysInMonth) {
        weeks.push(week);
        week = new Array(7).fill(null); // Start a new week
      }
    }

    return weeks;
  };

  let yearMonths = [];
  for (let i = 0; i < 12; i++) {
    let month = generateCalendarDays(i, currentYear);
    yearMonths.push(month);
    // console.log(month);
    if (i === 12) setCalendarDays(yearMonths);
  }

  // Functions
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to an array
    const newImages = [...images];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        newImages.push(reader.result); // Store each image as base64
        setImages(newImages);
      };
      reader.readAsDataURL(file); // Convert file to base64
    });
  };

  const handleHolidayAdd = () => {
    setHolidays((prevHolidays) => {
      const newHolidays = [
        {
          day: selectedDay,
          month: activePage,
          name: holidayName,
          color: color,
          icon: holidayIcon,
        },
        ...prevHolidays,
      ];
      return newHolidays;
    });
    setHolidayName("");
    document.getElementById("my_modal_5").close();
  };

  const isHoliday = (day, month) => {
    return holidays.some(
      (holiday) => holiday.day === day.toString() && holiday.month === month
    );
  };

  const handleHolidayRemove = () => {
    setHolidays((prevHolidays) =>
      holidays.filter((holiday) => {
        return holiday.day !== selectedDay || holiday.month !== activePage;
      })
    );
    setHolidayName("");
    setColor(null);
    document.getElementById("my_modal_5").close();
  };

  const handleCanvasStateChange = (newState) => {
    setCanvasStates((prevStates) => ({
      ...prevStates,
      [activePage]: {
        ...prevStates[activePage],
        ...newState,
      },
    }));
  };

  const handleSelectImage = (image) => {
    if (image === null) {
      setSelectedImage(null);
      setCanvasStates((prevStates) => ({
        ...prevStates,
        [activePage]: {
          ...prevStates[activePage],
          image: null,
          x: prevStates[activePage].x || 0,
          y: prevStates[activePage].y || 0,
          width: prevStates[activePage].width || A3_WIDTH,
          height: prevStates[activePage].height || A3_HEIGHT,
        },
      }));
      return;
    }
    const img = new window.Image();
    img.src = image;
    img.onload = () => {
      setSelectedImage(img);
      setCanvasStates((prevStates) => ({
        ...prevStates,
        [activePage]: {
          ...prevStates[activePage],
          image: img,
          x: prevStates[activePage].x || 0,
          y: prevStates[activePage].y || 0,
          width: prevStates[activePage].width || A3_WIDTH,
          height: prevStates[activePage].height || A3_HEIGHT,
        },
      }));
    };
  };

  const handleFrameChange = (frame) => {
    if (selectedFrame === frame) {
      setSelectedFrame(null);
      setCanvasStates((prevStates) => ({
        ...prevStates,
        [activePage]: {
          ...prevStates[activePage],
          frame: null,
        },
      }));
    } else {
      const img = new window.Image();
      img.src = frame;
      img.onload = () => {
        setSelectedFrame(frame);
        setCanvasStates((prevStates) => ({
          ...prevStates,
          [activePage]: {
            ...prevStates[activePage],
            frame: img,
          },
        }));
      };
    }
  };

  const checkCode = async (code) => {
    try {
      const response = await axios.get(
        `https://spokodruczki.pl:5000/api/code/${code}`
      );
      if (response.status === 404) {
        console.error("404");

        // If the code isn't found, redirect to localhost:3000
        window.location.href = "/";
      } else {
        console.log("Found order:");
        console.log(response.data);
        setUserdata(response.data);
      }
    } catch (error) {
      console.error("Error:", error);
      // Redirect to localhost:3000 in case of any error
      window.location.href = "/";
    }
  };

  const deleteCode = async () => {
    try {
      const response = await axios.get(
        `https://pokodruczki.pl:5000/api/useCode/${userdata}`
      );

      if (response.status === 404) {
        console.log("Code not found");
      } else {
        console.log("Code used successfully");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (userdata === null) {
    console.log("Checking code...");
    checkCode(id);
  }
  const handleExport = async (stageRef) => {
    console.log("Exporting...");
    setIsLoading(true);

    if (productType === "Kalendarz") {
      generateBarcode(userdata.buyer_phone);
      const barcodeCanvas = barcodeRef.current;
      const barcodeDataURL = barcodeCanvas.toDataURL("image/jpeg");

      const worker = new Worker(
        new URL("./workers/pdfWorker.js", import.meta.url)
      );

      setTimeout(() => {
        // Extract necessary data from fullSizeRefs
        const fullSizeRefsData = fullSizeRefs.current
          .map((ref) => {
            if (ref.current) {
              return {
                dataURL: ref.current.toDataURL({ pixelRatio: 100 / 3 }),
              };
            }
            return null;
          })
          .filter((data) => data !== null);

        worker.postMessage({
          fullSizeRefsData,
          A3_WIDTH,
          A3_HEIGHT,
          barcode: {
            dataURL: barcodeDataURL,
            width: barcodeCanvas.width,
            height: barcodeCanvas.height,
          },
        });

        worker.onmessage = async (event) => {
          const pdfBlob = event.data;
          await saveToServer(pdfBlob);
          setIsLoading(false);
        };
      }, 100);
    } else if (productType === "Kubek") {
      const stage = stageRef.current;
      if (stage) {
        const dataURL = stage.toDataURL({
          pixelRatio: 1 / scale,
          x: stage.x(),
          y: stage.y(),
          width: CUP_WIDTH * scale,
          height: CUP_HEIGHT * scale,
        });

        const response = await fetch(dataURL);
        const pngBlob = await response.blob();
        await saveToServer(pngBlob);
        setIsLoading(false);
      } else {
        console.error("Stage reference is not available.");
        setIsLoading(false);
      }
    }
  };

  const saveToServer = async (fileBlob) => {
    console.log("Saving to server...");
    const formData = new FormData();
    let filename;

    if (productType === "Kalendarz") {
      filename = `${userdata.format}_${userdata.buyer_phone}.pdf`;
    } else if (productType === "Kubek") {
      filename = `${userdata.format}_${userdata.buyer_phone}.png`;
    }

    formData.append("file", fileBlob, filename);
    formData.append("orderId", userdata.order_id);

    try {
      await axios.post("https://spokodruczki.pl:5000/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      window.location.href = "/success";
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const saveHistory = () => {
    setHistory([
      ...history,
      { page: activePage, state: canvasStates[activePage] },
    ]);
  };

  const undo = () => {
    if (history.length === 0) return;
    const previousState = history[history.length - 1];
    setRedoHistory([
      ...redoHistory,
      { page: activePage, state: canvasStates[activePage] },
    ]);
    setHistory(history.slice(0, -1));
    // Load previous state
    setTimeout(() => {
      setActivePage(previousState.page);
    }, 0);
    handleCanvasStateChange(previousState.state);
  };

  const redo = () => {
    if (redoHistory.length === 0) return;
    const lastState = redoHistory[redoHistory.length - 1];
    setHistory([
      ...history,
      { page: activePage, state: canvasStates[activePage] },
    ]);
    setRedoHistory(redoHistory.slice(0, -1));
    // Load the last state
    setTimeout(() => {
      setActivePage(lastState.page);
    }, 0);
    handleCanvasStateChange(lastState.state);
  };

  const loadImage = (url, callback) => {
    const img = new window.Image();
    img.src = url;
    img.onload = () => callback(img);
  };

  const stageToImage = (stageRef) => {
    const stage = stageRef.current;
    const scaleX = stage.scaleX();
    const scaleY = stage.scaleY();
    stage.scale({ x: 1, y: 1 });
    stage.draw();
    const dataURL = stage.toDataURL({
      x: stage.x(),
      y: stage.y(),
      width: A3_WIDTH,
      height: A3_HEIGHT,
    });
    stage.scale({ x: scaleX, y: scaleY });
    return dataURL;
  };

  const productConfigurations = {
    Kalendarz: {
      width: (297 / 25.4) * 300, // A3 width in px
      height: (420 / 25.4) * 300, // A3 height in px
      calendariumPath: (page) => `assets/calendariums/${page}.png`,
    },
    Kubek: {
      width: (188 / 25.4) * 300,
      height: (96 / 25.4) * 300,
      calendariumPath: () => null, // No calendarium for Kubek
    },
  };

  const productConfig = productConfigurations[productType] || {};

  useEffect(() => {
    if (userdata) {
      let productType;
      if (userdata.external_id.includes("KALENDARZ")) productType = "Kalendarz";
      if (userdata.external_id.includes("KUBEK")) productType = "Kubek";
      setProductType(productType);
      console.log(productType);
      if (barcodeRef.current) {
        generateBarcode(userdata.buyer_phone);
      }
    }
  }, [userdata, barcodeRef]);

  return (
    <AppContext.Provider
      value={{
        activeTool,
        setActiveTool,
        canvasStates,
        setCanvasStates,
        canvasThumbnails,
        setCanvasThumbnails,
        activePage,
        setActivePage,
        scale,
        setScale,
        isTransforming,
        setIsTransforming,
        selectedDay,
        setSelectedDay,
        holidayName,
        setHolidayName,
        color,
        setColor,
        handleCanvasStateChange,
        handleExport,
        undo,
        redo,
        showLeftPane,
        setShowLeftPane,
        showBottomPane,
        setShowBottomPane,
        PDFPages,
        isLoading,
        activePane,
        saveHistory,
        frame,
        setFrame,
        pages,
        images,
        handleImageChange,
        handleSelectImage,
        selectedImage,
        setPDFPages,
        setSelectedImage,
        stageToImage,
        holidays,
        handleHolidayAdd,
        setActivePane,
        selectedShape,
        setSelectedShape,
        selectedFrame,
        setSelectedFrame,
        handleFrameChange,
        deleteCode,
        handleHolidayRemove,
        A3_HEIGHT,
        A3_WIDTH,
        months,
        setImages,
        calendar,
        setCalendar,
        fullSizeRefs,
        calendarDays,
        setCalendarDays,
        isHoliday,
        currentYear,
        yearMonths,
        monthIndex,
        stagePosition,
        setStagePosition,
        icons,
        holidayIcon,
        setHolidayIcon,
        productType,
        CUP_WIDTH,
        CUP_HEIGHT,
        productConfig,
        userdata,
        setUserdata,
      }}
    >
      {children}
      <canvas ref={barcodeRef} style={{ display: "none" }}></canvas>
    </AppContext.Provider>
  );
};
