import React, { useEffect, useState, useContext } from "react";
import { Group, Rect, Text } from "react-konva";

import { AppContext } from "../AppContext";

const Icon = ({ calendarDayHeight, calendarDayWidth, icon = "heart" }) => {
  const icons = useContext(AppContext).icons;
  return (
    <Text
      text={icons[icon].code} // Unicode for FontAwesome gear icon
      fontFamily={icons[icon].fontFamily}
      fontSize={calendarDayHeight}
      width={calendarDayWidth}
      fill={"red"} // Color of the icon
      draggable
      align="center"
      verticalAlign="middle"

      // width={calendarDayWidth}
      // height={calendarDayHeight}
    />
  );
};

const Daynames = ({ dayNames, calendarDayHeight, calendarDayWidth }) => {
  return (
    <Group>
      {dayNames.map((dayName, index) => (
        <Group x={calendarDayWidth * index} key={index}>
          <Rect
            width={calendarDayWidth}
            height={calendarDayHeight / 2}
            // fill='lightgrey'
          />
          <Text
            text={dayName}
            fontSize={calendarDayHeight / 2.5}
            fontFamily="sans-serif"
            fill={dayName === "Nd" ? "#dd1111" : "black"}
            fontStyle="bold"
            width={calendarDayWidth}
            height={calendarDayHeight / 2}
            align="center"
            verticalAlign="middle"
          />
        </Group>
      ))}
    </Group>
  );
};

const Day = ({
  rowIndex,
  colIndex,
  calendarDayWidth,
  calendarDayHeight,
  day,
  activeTool,
  holidays,
  setHolidayName,
  setColor,
  month,
  handleDayClick,
  isHoliday,
}) => {
  return (
    <Group
      key={`${rowIndex}-${colIndex}`}
      x={colIndex * calendarDayWidth}
      y={rowIndex * calendarDayHeight + calendarDayHeight / 2}
    >
      <Rect
        width={calendarDayWidth}
        height={calendarDayHeight}
        // strokeWidth={1}
        // stroke={"black"}
      />
      {day && (
        <Group
          width={calendarDayWidth}
          height={calendarDayHeight}
          onClick={() => {
            if (activeTool === "Wybierz") {
              const holiday = holidays.find(
                (el) => el.day === day.toString() && el.month === month
              );
              setHolidayName(() => {
                if (holiday) {
                  return holiday.name;
                } else {
                  return "";
                }
              });
              setColor(holiday?.color || "#000000");
              handleDayClick(day);
            }
          }}
          onTap={() => {
            const holiday = holidays.find(
              (el) => el.day === day.toString() && el.month === month
            );
            setHolidayName(() => {
              if (holiday) {
                return holiday.name;
              } else {
                return "";
              }
            });
            setColor(holiday?.color || "#000000");
            handleDayClick(day);
          }}
          onMouseEnter={(e) => {
            const container = e.target.getStage().container();
            container.style.cursor = "pointer";
          }}
          onMouseLeave={(e) => {
            const container = e.target.getStage().container();
            container.style.cursor = "default";
          }}
        >
          {isHoliday(day, month) && (
            <>
              {holidays.find(
                (el) => el.day === day.toString() && el.month === month
              ).icon && (
                <Icon
                  calendarDayHeight={calendarDayHeight}
                  calendarDayWidth={calendarDayWidth}
                  icon={
                    holidays.find(
                      (el) => el.day === day.toString() && el.month === month
                    ).icon
                  }
                />
              )}
              <Text
                text={
                  holidays.find(
                    (el) => el.day === day.toString() && el.month === month
                  ).name
                }
                align="center"
                fontSize={calendarDayHeight / 5.5}
                verticalAlign="middle"
                y={calendarDayHeight / 3}
                width={calendarDayWidth}
                height={calendarDayHeight}
                fill={
                  isHoliday(day, month)
                    ? holidays.find(
                        (el) => el.day === day.toString() && el.month === month
                      )?.color || "#dd1111"
                    : colIndex === 6
                    ? "#dd1111"
                    : "black"
                }
                wrap="word"
              />
            </>
          )}

          <Text
            y={-(calendarDayHeight / 10)}
            text={day.toString()}
            fontSize={calendarDayHeight / 2}
            fontFamily="sans-serif"
            fill={
              isHoliday(day, month)
                ? holidays.find(
                    (el) => el.day === day.toString() && el.month === month
                  )?.color || "#dd1111"
                : colIndex === 6
                ? "#dd1111"
                : "black"
            }
            fontStyle="bold"
            width={calendarDayWidth}
            height={calendarDayHeight}
            align="center"
            verticalAlign="middle"
          />
        </Group>
      )}
    </Group>
  );
};

const MonthName = ({
  month,
  currentYear,
  calendarDayHeight,
  calendarDayWidth,
}) => {
  return (
    <Text
      text={`${month} ${currentYear}`}
      fontSize={calendarDayHeight / 1.7}
      fontFamily="sans-serif"
      fill="black"
      fontStyle="bold"
      x={calendarDayWidth / 3.3}
    />
  );
};

const Calendar = ({
  artboardSize,
  calendarWidth,
  calendarY,
  currentYear,
  calendarDayWidth,
  calendarDayHeight,
  daysArray,
  dayNames,
  setHolidayName,
  holidays,
  setColor,
  handleDayClick,
  isHoliday,
  month,
  activeTool,
}) => {
  return (
    <Group x={(artboardSize.width - calendarWidth) / 2} y={calendarY}>
      <MonthName
        month={month}
        currentYear={currentYear}
        calendarDayHeight={calendarDayHeight}
        calendarDayWidth={calendarDayWidth}
      />
      <Group y={calendarDayWidth / 1.6}>
        <Daynames
          dayNames={dayNames}
          calendarDayHeight={calendarDayHeight}
          calendarDayWidth={calendarDayWidth}
        />
        {daysArray &&
          daysArray.map((week, rowIndex) =>
            week.map((day, colIndex) => (
              <Day
                rowIndex={rowIndex}
                colIndex={colIndex}
                calendarDayWidth={calendarDayWidth}
                calendarDayHeight={calendarDayHeight}
                day={day}
                activeTool={activeTool}
                holidays={holidays}
                setHolidayName={setHolidayName}
                setColor={setColor}
                month={month}
                handleDayClick={handleDayClick}
                isHoliday={isHoliday}
              />
            ))
          )}
      </Group>
    </Group>
  );
};

export default Calendar;
