// Libraries
import React, { useEffect, useRef, useState, useContext } from "react";

import { AppContext } from "../AppContext";

// Components
import Navigation from "./Navigation";
import Images from "./Images";
import Pages from "./Pages";
import Canvas from "./Canvas";
import Tools from "./Tools";
import Dialog from "./Dialog";
import Frames from "./Frames";
import Text from "./Text";
import Settings from "./Settings";
import { PiCaretCircleRightFill } from "react-icons/pi";
import { PiCaretCircleLeftFill } from "react-icons/pi";
import { PiCaretCircleDownFill } from "react-icons/pi";
import { PiCaretCircleUpFill } from "react-icons/pi";
import Layers from "./Layers";

const Editor = () => {
  const {
    showLeftPane,
    setShowLeftPane,
    showBottomPane,
    setShowBottomPane,
    activePane,
    PDFPages,
    isLoading,
    setCanvasStates,
    activePage,
    A3_WIDTH,
    A3_HEIGHT,
    productType,
    pages,
  } = useContext(AppContext);
  const wrapperRef = useRef(null);
  const stageRef = useRef(null);
  const fileInputRef = useRef(null);
  const artboardRef = useRef(null);
  const dialogRef = useRef(null);

  const setImage = (imageSrc) => {
    if (imageSrc === null) {
      setCanvasStates((prevState) => ({
        ...prevState,
        [activePage]: {
          ...prevState[activePage],
          image: null,
          width: 0,
          height: 0,
        },
      }));
      return;
    }
    const img = new Image();
    img.onload = () => {
      setCanvasStates((prevState) => ({
        ...prevState,
        [activePage]: {
          ...prevState[activePage],
          image: img,
          width: A3_WIDTH,
        },
      }));
    };
    img.src = imageSrc;
  };

  return (
    <div className="flex h-screen w-screen overflow-hidden bg-slate-300">
      <Navigation />
      {activePane && showLeftPane && (
        <div className="flex flex-col gap-2 p-2 pt-4 w-64 bg-gray-200 border border-r-gray-300 border-l-gray-300 border-1">
          {activePane === "Zdjęcia" && (
            <Images fileInputRef={fileInputRef} setImage={setImage} />
          )}
          {activePane === "Ramki" && <Frames />}
          {activePane === "Tekst" && <Text />}
          {activePane === "Warstwy" && <Layers />}
          {activePane === "Ustawienia" && <Settings />}
        </div>
      )}
      <div
        ref={wrapperRef}
        className="flex max-h-screen flex-col overflow-hidden items-stretch w-full"
      >
        <Tools stageRef={stageRef} />
        <Canvas
          stageRef={stageRef}
          artboardRef={artboardRef}
          dialogRef={dialogRef}
        />

        <div className="flex flex-col relative">
          {/* {showBottomPane && ( */}
          {showBottomPane && productType === "Kalendarz" && (
            <Pages stageRef={stageRef} pages={pages} />
          )}
          {/* <button
            className="btn flex absolute -top-20 p-2 -left-2"
            onClick={() => setShowLeftPane(!showLeftPane)}
          >
            {showLeftPane ? (
              <PiCaretCircleLeftFill size={"1.6em"} />
            ) : (
              <PiCaretCircleRightFill size={"1.6em"} />
            )}
          </button> */}
          {productType === "Kalendarz" && (
            <button
              className="btn flex absolute -top-10 -left-2 p-2"
              onClick={() => setShowBottomPane(!showBottomPane)}
            >
              {showBottomPane ? (
                <PiCaretCircleDownFill size={"1.6em"} />
              ) : (
                <PiCaretCircleUpFill size={"1.6em"} />
              )}
            </button>
          )}
        </div>
        <Dialog dialogRef={dialogRef} />
      </div>
      {isLoading === true && (
        <div className="absolute w-full h-full flex items-center justify-center backdrop-blur-sm">
          <div className="bg-black w-full h-full absolute opacity-60"></div>
          <div className="flex flex-col items-center">
            <span className="loading loading-lg text-white"></span>
            <p className="text-xl text-white z-50">
              Trwa zapis na serwerze... Może to zająć minutkę...
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Editor;
