import React from "react";

import { LuZoomIn } from "react-icons/lu";
import { LuZoomOut } from "react-icons/lu";
import { TbZoomReset } from "react-icons/tb";

const Zoom = ({
  scale,
  setScale,
  stageHeight,
  stageWidth,
  artboardHeight,
  artboardWidth,
  setStagePosition,
  setIsZooming,
  stagePosition,
}) => {
  const zoom = parseInt(100 * scale);
  return (
    <div className="flex flex-row items-center gap-2">
      <div className="tooltip tooltip-top" data-tip={"Oddal"}>
        <button
          className="btn"
          onClick={() => {
            setIsZooming(true);
            setStagePosition({
              x:
                (artboardWidth * scale) / 2 -
                (artboardWidth / 2) * scale * 0.9 +
                stagePosition.x,
              y:
                (artboardHeight * scale) / 2 -
                (artboardHeight / 2) * scale * 0.9 +
                stagePosition.y,
            });
            setScale(scale * 0.9);
          }}
        >
          <LuZoomOut size={"1.4em"} />
        </button>
      </div>
      {/* <h1>Zoom: {zoom}%</h1> */}
      <div className="tooltip tooltip-top" data-tip={"Przybliż"}>
        <button
          className="btn"
          onClick={() => {
            setIsZooming(true);
            setStagePosition({
              x:
                (artboardWidth * scale) / 2 -
                (artboardWidth / 2) * scale * 1.1 +
                stagePosition.x,
              y:
                (artboardHeight * scale) / 2 -
                ((artboardHeight * scale) / 2) * 1.1 +
                stagePosition.y,
            });
            setScale(scale * 1.1);
          }}
        >
          <LuZoomIn size={"1.4em"} />
        </button>
      </div>
      <div className="tooltip tooltip-top" data-tip={"Dopasuj"}>
        <button
          className="btn"
          onClick={() => {
            // Calculate the scale to fit the artboard within the stage
            const scaleX = stageWidth / artboardWidth;
            const scaleY = stageHeight / artboardHeight;
            const newScale = Math.min(scaleX, scaleY) * 0.95; // Apply a margin
            if (scale !== newScale) {
              setScale(newScale);

              // Center the artboard
              setStagePosition({
                x: (stageWidth - artboardWidth * newScale) / 2,
                y: (stageHeight - artboardHeight * newScale) / 2,
              });
            }
          }}
        >
          <TbZoomReset size={"1.4em"} />
        </button>
      </div>
    </div>
  );
};

export default Zoom;
