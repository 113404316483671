import React, { useEffect, useState, useContext } from "react";
import { ChromePicker } from "react-color";

import { AppContext } from "../AppContext";

import { FaRegTrashAlt } from "react-icons/fa";
import { FaRegSave } from "react-icons/fa";

const Dialog = ({ dialogRef }) => {
  const {
    handleHolidayAdd,
    holidayName,
    setHolidayName,
    color,
    setColor,
    setSelectedDay,
    handleHolidayRemove,
    icons,
    holidayIcon,
    setHolidayIcon,
  } = useContext(AppContext);

  // useEffect(() => {
  //   if
  // }, [holidayName, color]);

  return (
    <dialog id="my_modal_5" className="modal modal-middle" ref={dialogRef}>
      <div className="modal-box flex flex-col items-center w-max">
        <h3 className="font-bold text-lg">Dodaj własne święto</h3>
        <p className="py-4">
          Podaj nazwę święta, a następnie kliknij przycisk 'Zapisz'.
        </p>
        <input
          type="text"
          className="input w-full border border-neutral-600"
          placeholder="Nazwa święta"
          value={holidayName}
          onChange={(e) => setHolidayName(e.target.value)}
          maxLength={20}
        />
        <span className="block w-full text-right px-3 relative -top-9 pointer-events-none">
          {holidayName.length}/20
        </span>
        {/* <div className="flex items-center gap-2 pb-4">
          <label>Ikonka święta:</label>
          <select
            className="select"
            style={{ fontFamily: "Font Awesome" }}
            onChange={(e) => {
              setHolidayIcon(e.target.value);
              console.log(e.target.value);
            }}
          >
            <option selected>Brak</option>
            {Object.keys(icons).map((icon, index) => {
              return (
                <option
                  key={index}
                  style={{ fontFamily: icons[icon].fontFamily }}
                  selected={index === 0 ? true : false}
                  className="text-lg inline text-center"
                  value={icon}
                >
                  {icons[icon].code}
                </option>
              );
            })}
          </select>
        </div> */}
        <ChromePicker
          color={{ hex: color || color }}
          onChangeComplete={(newColor) => setColor(newColor.hex)}
          // hide alpha slider
          disableAlpha={true}
          // change theme to light
          theme="light"
        />
        {/* Closing Dialog */}
        <div className="modal-action flex flex-col">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <div className="flex gap-2">
              <button
                className="btn btn-primary text-white min-w-36"
                onClick={() => {
                  handleHolidayAdd();
                  setSelectedDay(null);
                }}
              >
                <FaRegSave size={"1.3em"} />
                Zapisz
              </button>
              <button
                className="btn min-w-36"
                onClick={() => {
                  handleHolidayRemove();
                  setSelectedDay(null);
                }}
              >
                <FaRegTrashAlt size={"1.1em"} />
                Usuń
              </button>
            </div>
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              ✕
            </button>
          </form>
        </div>
      </div>
      <form method="dialog" className="modal-backdrop">
        {/* if there is a button in form, it will close the modal */}
        <button onClick={() => setSelectedDay(null)}>Close</button>
      </form>
    </dialog>
  );
};

export default Dialog;
