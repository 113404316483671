import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "../AppContext";
import { useDropzone } from "react-dropzone";
import { TiDelete } from "react-icons/ti";

const Images = ({ setImage, image }) => {
  const {
    setIsTransforming,
    setSelectedShape,
    setImages,
    images,
    selectedImage,
    setSelectedImage,
    setCanvasStates,
    activePage,
    productType,
    A3_WIDTH,
    CUP_HEIGHT,
    artboardSize,
  } = useContext(AppContext);

  const fileInputRef = useRef(null);

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setImages((prevImages) => [
      ...prevImages,
      ...files.map((file) => URL.createObjectURL(file)),
    ]);
  };

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
    });
  };

  const handleImageClick = async (image) => {
    if (selectedImage === image) {
      setSelectedImage(null);
      // setImage(null);
      setIsTransforming(false);
      setSelectedShape(null);
      return;
    }
    // setImage(image);
    // setSelectedImage(image);
    try {
      const loadedImage = await loadImage(image);
      const element = {
        type: "image",
        image: loadedImage,
        x: 0,
        y: 0,
        width: productType === "Kalendarz" ? A3_WIDTH : CUP_HEIGHT,
        height: productType === "Kalendarz" ? A3_WIDTH : CUP_HEIGHT,
        scaleX: 1,
        scaleY: 1,
        rotation: 0,
      };
      setCanvasStates((prevState) => ({
        ...prevState,
        [activePage]: {
          ...prevState[activePage],
          elements: [...prevState[activePage].elements, element],
        },
      }));
    } catch (error) {
      console.error("Failed to load image:", error);
    }
  };

  const onDrop = (acceptedFiles) => {
    setImages((prevImages) => [
      ...prevImages,
      ...acceptedFiles.map((file) => URL.createObjectURL(file)),
    ]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="flex flex-col max-w-48 gap-2 text-center h-full">
      {images.length > 0 && (
        <span className="text-lg mb-2 font-medium">Wybierz zdjęcie:</span>
      )}
      <div
        {...getRootProps()}
        className={`h-full gap-2 overflow-y-auto overflow-x-hidden cursor-auto drop-area ${
          isDragActive ? "drag-over" : ""
        }`}
        // disable click event
        onClick={(e) => {
          // console.log(images.length);
          if (images.length > 0) {
            e.stopPropagation();
          } else {
            fileInputRef.current.click();
          }
        }}
      >
        <input {...getInputProps()} />
        {images.length === 0 ? (
          <div className="border h-full border-neutral-300 border-2 border-dashed rounded-xl flex flex-grow-1 items-center justify-center">
            <p className="text-gray-500">
              Przeciągnij tu swoje zdjęcia lub użyj przycisku pod spodem
            </p>
          </div>
        ) : (
          images.map((image, index) => (
            <div
              className={`p-1 border border-2 border rounded-xl min-w-44 cursor-pointer ${
                selectedImage !== null && selectedImage === image
                  ? "border-primary hover:border-primary"
                  : "border-transparent hover:border-neutral"
              }`}
              key={index}
              onClick={() => {
                handleImageClick(image);
              }}
            >
              {/* here */}
              <div className="overflow-hidden rounded-md relative">
                <img
                  src={image}
                  alt={`Uploaded Preview ${index}`}
                  draggable={false}
                />
                {selectedImage === image && (
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity text-white">
                    <TiDelete size={"4rem"} />
                  </div>
                )}
              </div>
            </div>
          ))
        )}
        {isDragActive && (
          <div className="absolute w-full h-full bg-primary top-0 left-0">
            <div className="flex w-full h-full items-center justify-center">
              <span className="text-black text-lg font-bold">
                Upuść tu swoje zdjęcia
              </span>
            </div>
          </div>
        )}
      </div>

      <input
        type="file"
        ref={fileInputRef}
        className="file-input file-input-bordered w-full max-w-xs mb-10"
        multiple
        onChange={handleImageChange}
        hidden
      />
      <button
        className="flex flex-0 btn btn-primary min-w-full text-white"
        onClick={() => fileInputRef.current.click()}
      >
        Prześlij obrazy
      </button>
    </div>
  );
};

export default Images;
