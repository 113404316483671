import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";
import {
  Stage,
  Layer,
  Group,
  Rect,
  Text,
  Image as KonvaImage,
} from "react-konva";
import Calendar from "./Calendar";

const Calendarium = ({ page }) => {
  const { canvasWidth, canvasHeight } = useContext(AppContext);
  const [image, setImage] = useState(null);

  useEffect(() => {
    const img = new window.Image();
    img.src = require(`../assets/calendariums/${page}.png`); // Replace with your image URL
    img.onload = () => {
      setImage(img); // Set the image once it's loaded
    };
    img.onerror = () => {
      console.error("Failed to load image. Check the URL.");
    };
  }, [page]);

  return (
    <KonvaImage
      x={0}
      y={0}
      width={canvasWidth}
      height={canvasHeight}
      image={image}
    />
  );
};

const Pages = ({ stageRef, pages }) => {
  const {
    activePage,
    setActivePage,
    canvasThumbnails,
    setIsTransforming,
    setSelectedImage,
    setPDFPages,
    PDFPages,
    stageToImage,
    setSelectedShape,
    canvasStates,
    calendariumImages,
    fullSizeRefs,
    artboardRef,
    calendarDays,
    holidays,
    isHoliday,
    yearMonths,
    exportScale,
    productConfig,
  } = useContext(AppContext);
  const canvasState = canvasStates[activePage];

  const canvasWidth = productConfig.width;
  const canvasHeight = productConfig.height;

  const [artboardSize, setArtboardSize] = useState({
    width: canvasWidth,
    height: canvasHeight,
  });
  const calendarWidth = 0.9 * artboardSize.width;
  const calendarDayWidth = calendarWidth / 7;
  const calendarDayHeight = calendarDayWidth * 0.7;
  const calendarY = artboardSize.height / 2;
  const [backgroundColor, setBackgroundColor] = useState("white");

  // console.log(yearMonths["Styczeń"]);

  return (
    <div className="flex flex-0 py-4 gap-4 bg-gray-200 items-center px-10 overflow-x-scroll border border-1 border-t-gray-300">
      {pages.map((page, index) => {
        const lol = canvasStates[page];
        // const calendarium = calendariumImages[page];
        return (
          <div
            className={`box-border border-2 p-1 rounded-xl flex flex-col items-center cursor-pointer ${
              activePage === page
                ? "border-primary hover:border-gray-500"
                : "border-gray-300 hover:border-gray-500"
            }`}
            onClick={() => {
              setIsTransforming(false);
              setSelectedShape(null);
              setActivePage(page);
            }}
            key={page}
          >
            <div className="bg-white rounded-md overflow-hidden">
              <StageTemp
                lol={lol}
                canvasWidth={canvasWidth}
                canvasHeight={canvasHeight}
                fullSizeRefs={fullSizeRefs}
                index={index}
                artboardRef={artboardRef}
                page={page}
                artboardSize={artboardSize}
                calendarWidth={calendarWidth}
                calendarY={calendarY}
                canvasState={canvasState}
                calendarDayWidth={calendarDayWidth}
                calendarDayHeight={calendarDayHeight}
                holidays={holidays}
                isHoliday={isHoliday}
                yearMonths={yearMonths}
                pages={pages}
              />
            </div>
            <span className="select-none font-medium text-gray-500">
              {page}
            </span>
          </div>
        );
      })}
    </div>
  );
};

const StageTemp = ({
  lol,
  canvasWidth,
  canvasHeight,
  fullSizeRefs,
  index,
  artboardRef,
  page,
  artboardSize,
  calendarWidth,
  calendarY,
  canvasState,
  calendarDayWidth,
  calendarDayHeight,
  holidays,
  isHoliday,
  yearMonths,
  pages,
}) => {
  return (
    <Stage
      width={canvasWidth * 0.03}
      height={canvasHeight * 0.03}
      scaleX={0.03}
      scaleY={0.03}
      // TODO: To do poprawy
      x={0}
      y={0}
      // className="border border-1 border-black"
      ref={fullSizeRefs.current[index]}
      listening={false}
    >
      <Layer>
        <Group
          x={0}
          y={0}
          ref={artboardRef}
          width={canvasWidth}
          height={canvasHeight}
          clip={{
            x: 0,
            y: 0,
            width: canvasWidth,
            height: canvasHeight,
          }}
        >
          <Rect fill={"white"} width={canvasWidth} height={canvasHeight} />

          {lol.elements.map((element, index) =>
            element.type === "text" ? (
              <Text
                key={index}
                x={element.x}
                y={element.y}
                text={element.text}
                fontSize={element.size * (300 / 72)} // in points (pt)
                fill={element.color}
                scaleX={element.scaleX}
                scaleY={element.scaleY}
                rotation={element.rotation || 0}
                fontFamily={element.font}
              />
            ) : element.type === "image" ? (
              <KonvaImage
                key={index}
                image={element.image}
                x={element.x}
                y={element.y}
                width={element.width}
                height={
                  (element.width / element.image.width) * element.image.height
                }
                scaleX={element.scaleX}
                scaleY={element.scaleY}
                rotation={element.rotation || 0}
              />
            ) : null
          )}

          {/* Frame */}
          {lol.frame && (
            <KonvaImage
              x={0}
              y={0}
              width={canvasWidth || 0}
              image={lol.frame}
              listening={false}
            />
          )}

          {/* Calendarium BG */}
          <Calendarium page={page} />

          {/* Calendarium */}
          {lol.name !== "Okładka" && (
            <Calendar
              artboardSize={artboardSize}
              calendarWidth={calendarWidth}
              calendarY={calendarY}
              canvasState={canvasState}
              currentYear={2025}
              calendarDayWidth={calendarDayWidth}
              calendarDayHeight={calendarDayHeight}
              daysArray={yearMonths[pages.indexOf(page) - 1]}
              dayNames={["Pn", "Wt", "Śr", "Cz", "Pt", "So", "Nd"]}
              holidays={holidays}
              isHoliday={isHoliday}
              month={lol?.name}
            />
          )}
        </Group>
      </Layer>
    </Stage>
  );
};

export default Pages;
