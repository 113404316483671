import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../AppContext";
import { TiDelete } from "react-icons/ti";

const Frames = () => {
  const { selectedFrame, setSelectedFrame, handleFrameChange } =
    useContext(AppContext);
  const [frames, setFrames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const importAll = (r) => r.keys().map(r);
    const images = importAll(
      require.context("../assets/ramki", false, /\.(png|jpe?g|svg)$/)
    );
    setFrames(images);
    setIsLoading(false);
  }, []);

  return (
    <div className="flex flex-col max-w-48 gap-2 text-center h-full overflow-y-auto overflow-x-hidden">
      <span className="text-lg pb-3">Wybierz ramkę:</span>
      {frames.map((frame, index) =>
        isLoading ? (
          <div className="skeleton h-28 w-44"></div>
        ) : (
          <div
            className={`p-1 border border-2 border rounded-xl min-w-44 cursor-pointer ${
              selectedFrame !== null && selectedFrame === frame
                ? "border-primary hover:border-primary"
                : "border-transparent hover:border-neutral"
            }`}
            onClick={() => handleFrameChange(frame)}
            key={index}
          >
            <div className="overflow-hidden rounded-md relative">
              <img
                key={index}
                src={frame}
                alt={`Frame ${index}`}
                draggable={false}
              />
              {selectedFrame === frame && (
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity text-white">
                  <TiDelete size={"4rem"} />
                </div>
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Frames;
