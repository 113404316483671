import React, { useContext, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { AppContext } from "../AppContext";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineTextFields } from "react-icons/md";
import { FaGripVertical } from "react-icons/fa";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const ItemType = "ELEMENT";

const DraggableElement = ({
  element,
  index,
  moveElement,
  handleDeleteElement,
}) => {
  const ref = React.useRef(null);
  const handleRef = React.useRef(null);

  const [, drop] = useDrop({
    accept: ItemType,
    hover(item) {
      if (item.index !== index) {
        moveElement(item.index, index);
        item.index = index;
      }
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(handleRef);
  drop(ref);

  return (
    <div
      ref={ref}
      className={`flex items-center justify-between border bg-gray-100 border-1 border-gray-300 p-2 mb-1 rounded-md h-12 ${
        isDragging ? "opacity-50" : ""
      }`}
    >
      <div ref={handleRef} className="cursor-move mr-1 text-gray-400">
        <FaGripVertical />
      </div>
      {element.type === "text" ? (
        <>
          <div className="flex items-center justify-center border-2 border-gray-400 h-8 aspect-square rounded-md bg-gray-200">
            <MdOutlineTextFields size={"1.4em"} className="text-gray-500" />
          </div>
          <span className="text-left w-full text-md mx-2 text-gray-500 font-medium text-nowrap overflow-hidden overflow-ellipsis">
            {element.text}
          </span>
        </>
      ) : (
        <div className="flex">
          <img src={element.image.src} alt="Layer" className="w-8 rounded-md" />
          <span className="text-md flex flex-1 ml-2 text-gray-500">Obraz</span>
        </div>
      )}
      <button
        className="border border-1 w-8 aspect-square flex items-center justify-center rounded-md hover:bg-gray-200"
        onClick={() => handleDeleteElement(index)}
      >
        <FaRegTrashAlt className="text-gray-600 mx-1" />
      </button>
    </div>
  );
};

const Layers = () => {
  const {
    canvasStates,
    setCanvasStates,
    activePage,
    setSelectedShape,
    setIsTransforming,
  } = useContext(AppContext);

  const handleDeleteElement = (index) => {
    setCanvasStates((prevState) => {
      const updatedTexts = prevState[activePage].elements.filter(
        (_, i) => i !== index
      );
      setSelectedShape(null);
      setIsTransforming(false);
      return {
        ...prevState,
        [activePage]: {
          ...prevState[activePage],
          elements: updatedTexts,
        },
      };
    });
  };

  const moveElement = (fromIndex, toIndex) => {
    setCanvasStates((prevState) => {
      const updatedElements = [...prevState[activePage].elements];
      const [movedElement] = updatedElements.splice(fromIndex, 1);
      updatedElements.splice(toIndex, 0, movedElement);
      return {
        ...prevState,
        [activePage]: {
          ...prevState[activePage],
          elements: updatedElements,
        },
      };
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-col items-stretch gap-2 text-center h-full">
        <span className="text-lg mb-2 font-medium">Warstwy:</span>
        <div className="h-full overflow-y-auto overflow-x-hidden">
          <div className="flex flex-col-reverse items-stretch">
            {canvasStates[activePage].elements.map((element, index) => (
              <DraggableElement
                key={index}
                index={index}
                element={element}
                moveElement={moveElement}
                handleDeleteElement={handleDeleteElement}
              />
            ))}
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default Layers;
