import React from "react";
import { AppProvider } from "./AppContext";
import Editor from "./components/Editor";

function App() {
  return (
    <AppProvider>
      <Editor />
    </AppProvider>
  );
}

export default App;
